import {Component, Input} from '@angular/core';
import {NgOptimizedImage} from '@angular/common';
import {NoteoNotyConst} from '@shared/noteo/noteo.constants';

/*
| Hauteur du parent | Margin bottom | Taille de la police | Ratio  |
|-------------------|---------------|---------------------|--------|
| 20px              | -3px          | 16px                | 0,8    |
*/

@Component({
    imports: [NgOptimizedImage],
    selector: 'app-noteo-logo-noty',
    standalone: true,
    styleUrl: 'noteo-logo.noty.component.scss',
    templateUrl: 'noteo-logo.noty.component.html',
})
export class AppNoteoLogoNotyComponent {
    private _srcImage = NoteoNotyConst.srcImage;

    @Input()
    set isWhite(value: boolean) {
        this._srcImage = value ? NoteoNotyConst.srcImageWhite : NoteoNotyConst.srcImage;
    }

    get label(): string {
        return NoteoNotyConst.label;
    }

    get srcImage(): string {
        return this._srcImage;
    }
}
