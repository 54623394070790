<div class="tw-mb-5">
  <h3><fa-icon [icon]="'edit'"/> Mise à jour des données</h3>
</div>

<form (ngSubmit)="update(updateForm)" #updateForm="ngForm">
  <div class="tw-divide-y">
    <div class="sm:tw-grid sm:tw-grid-cols-2 tw-divide-y sm:tw-divide-y-0 sm:tw-divide-x sm:tw-pb-4">
      <div class="sm:tw-pr-4">
        <div class="tw-py-4 tw-space-y-4">
          <!-- 16px et mb -3px pour 20px -->
          <div class="tw-text-center"><app-noteo-logo-noty class="tw-h-[16px] tw-mb-[-3px]"/></div>

          <ng-container *ngTemplateOutlet="dataValeurVenale;context:{data: notyData}"/>
        </div>
      </div>

      <div class="sm:tw-pl-4">
        <div class="tw-py-4 tw-space-y-4">
          <!-- 14px pour 20px -->
          <div class="tw-text-center"><app-noteo-logo-notestim class="tw-h-[14px]"/></div>

          <ng-container *ngTemplateOutlet="dataValeurVenale;context:{data: notestimData}"/>
        </div>
      </div>

      <ng-template #dataValeurVenale let-data="data">
        <p class="tw-p-0 tw-space-y-2">
          @if (data.valeurVenale > 0) {
            Valeur vénale de

            <span class="tw-text-base tw-font-semibold tw-text-gray-700">
              {{data.valeurVenale | textCurrency}}
            </span>
          } @else {
            Pas de valeur vénale saisie<span class="tw-text-base"> </span>
          }
        </p>
      </ng-template>
    </div>

    <div class="tw-flex tw-justify-center tw-py-4 tw-space-y-4">
<!-- En cas de données supplémentaires à valider, l'utilisation de ce titre sera utile
    <div class="tw-py-4 tw-space-y-4">
      <div class="tw-text-center">Données retenues</div>
-->

      <div [appFormError]="valeurVenale">
        <label for="valeur-venale">Valeur vénale retenue</label>

        <div class="tw-input-currency">
          <input type="number" class="tw-input-number" id="valeur-venale" step="0.01" min="0" name="valeurVenale"
                 #valeurVenale="ngModel" [(ngModel)]="updatedData.valeurVenale" pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$"
                 required>

          <span>€</span>
        </div>
      </div>
    </div>
  </div>

  <div class="sm:tw-flex sm:tw-flex-row-reverse tw-gap-4 tw-mt-5 sm:tw-mt-4">
    <button class="tw-btn-primary-info" type="submit">Enregistrer</button>
  </div>
</form>
