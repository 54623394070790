import {Component, Input} from '@angular/core';
import Etude from '@models/etudes/etude/etude.model';
import {NgOptimizedImage} from '@angular/common';

/*
| Hauteur du parent | Taille de la police | Ratio  |
|-------------------|---------------------|--------|
| 16px              | 11px                | 0,6875 |
| 17px              | 12px                | 0,7059 |
| 20px              | 14px                | 0,7    |
| 25px              | 17px                | 0,68   |
| 28px              | 20px                | 0,7143 |
| 33px              | 23px                | 0,6970 |
*/

@Component({
    imports: [NgOptimizedImage],
    selector: 'app-noteo-logo-notestim',
    standalone: true,
    styleUrl: 'noteo-logo.notestim.component.scss',
    templateUrl: 'noteo-logo.notestim.component.html',
})
export class AppNoteoLogoNotestimComponent {
    private _srcImage = Etude.enablableModules.NOTESTIM.srcImage;

    @Input()
    set isWhite(value: boolean) {
        this._srcImage = value ? Etude.enablableModules.NOTESTIM.srcImageWhite : Etude.enablableModules.NOTESTIM.srcImage;
    }

    get label(): string {
        return Etude.enablableModules.NOTESTIM.label;
    }

    get srcImage(): string {
        return this._srcImage;
    }
}
