import {inject, Injectable} from '@angular/core';
import LocationPasserelle from '@models/locations/location/passerelles/passerelle/location-passerelle.model';
import {combineLatest, Observable, of} from 'rxjs';
import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';
import Location from '@models/locations/location/location.model';
import {LocationPasserelleService} from '@models/locations/location/passerelles/passerelle/location-passerelle.service';

@Injectable({providedIn: 'root'})
export class LocationPasserellesService {
    private _locationPasserelleService = inject(LocationPasserelleService);

    getAllFromEtudePasserelles$(location: Location, etudePasserelles: EtudePasserelle[] = []): Observable<LocationPasserelle[]> {
        if (etudePasserelles.length <= 0) {
            return of([]);
        }

        return combineLatest(etudePasserelles.map(etudePasserelle => this._locationPasserelleService.getOneSaved$(location, etudePasserelle)));
    }
}
