import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {ErrorSentryService} from '@core/error/error.sentry.service';
import {User as UserSentry} from '@sentry/types';
import User from '@models/users/user/user.model';
import Etude from '@models/etudes/etude/etude.model';

@Injectable()
export class ErrorConfigurationService {
    private _ignoredErrors: string[] = [];
    private _errorSentryService = inject(ErrorSentryService);

    setUser(user: User = null!, etude: Etude = null!): void {
        const userSentry: UserSentry = {};

        if (user !== null) {
            userSentry.id = user.id.toString();
            userSentry.email = user.emailAddress;
            userSentry.nom = user.nom;
            userSentry.prenom = user.prenom;
            if (etude !== null) {
                userSentry.etudeId = etude.id;
                userSentry.etudeCRPCEN = etude.crpcen;
                userSentry.etudeRaisonSociale = etude.raisonSociale;
            }
        }

        this._errorSentryService.setUser(userSentry);
    }

    addIgnoredError(error: string): void {
        this._ignoredErrors.push(error);
    }

    isErrorIgnored(error: unknown): boolean {
        let stringError: string;

        if (error instanceof HttpErrorResponse) {
            stringError = error.message;
            if (500 <= error.status && error.status < 600) {
                return true;
            }

            if ([0, 401].includes(error.status)) {
                return true;
            }
        } else if (error instanceof Error) {
            stringError = error.message;
        } else {
            try {
                stringError = (error as { toString(): string }).toString();
            } catch (e: unknown) {
                stringError = (e as { toString(): string }).toString();
            }
        }

        return this._ignoredErrors.some(ignoredError => stringError.trim().includes(ignoredError));
    }
}
