import {Component, inject} from '@angular/core';
import {FormsModule, NgForm} from '@angular/forms';
import {SharedModule} from '@shared/shared.module';
import {ModalService} from '@shared/modal/modal.service';
import {
    IEstimationRapportReadNotestimUpdateData, IEstimationRapportReadNotestimUpdateDataObject
} from '@features/estimations/estimations.interfaces';
import {AppNoteoLogoNotestimComponent} from '@shared/noteo/logo/notestim/noteo-logo.notestim.component';
import {AppNoteoLogoNotyComponent} from '@shared/noteo/logo/noty/noteo-logo.noty.component';
import {NgTemplateOutlet} from '@angular/common';

@Component({
    imports: [AppNoteoLogoNotestimComponent, AppNoteoLogoNotyComponent, FormsModule, NgTemplateOutlet, SharedModule],
    selector: 'estimation-rapport-read-notestim-update',
    standalone: true,
    templateUrl: 'estimation-rapport.read-notestim-update.component.html'
})
export class EstimationRapportReadNotestimUpdateComponent {
    private _modalService = inject(ModalService);
    private _notestimData!: IEstimationRapportReadNotestimUpdateDataObject;
    private _notyData!: IEstimationRapportReadNotestimUpdateDataObject;
    private _updatedData!: IEstimationRapportReadNotestimUpdateDataObject;

    set data(value: IEstimationRapportReadNotestimUpdateData) {
        this._notestimData = value.notestim;
        this._notyData = value.noty;
        this._updatedData = {...this._notestimData};
    }

    get notestimData(): IEstimationRapportReadNotestimUpdateDataObject {
        return this._notestimData;
    }

    get notyData(): IEstimationRapportReadNotestimUpdateDataObject {
        return this._notyData;
    }

    get updatedData(): IEstimationRapportReadNotestimUpdateDataObject {
        return this._updatedData;
    }

    update(ngForm: NgForm): void {
        if (!ngForm.valid) {
            return;
        }

        this._modalService.setResponse(this._updatedData);
    }
}
