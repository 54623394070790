import angularJS from '@shared/angularJS/global.ng';
import {find} from 'lodash';
import {IModule} from 'angular';
import {NgSoqModalesManager} from '@legacy/app/soqrate/soqrate';
import {NgFilter} from '@legacy/app/managers/ressources';
import {NgFilterManager} from '@legacy/app/managers/managers';
import {NgFiltersCollection} from '@legacy/app/managers/collections';
import Filter from '@models/filters/filter/filter.model';

export default function getEskSearchBarre(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-search-barre></esk-search-barre>
         */
        module.component('eskSearchBarre', {
            bindings: {type: '@', onChange: '='},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/search/barre.html'
        });

        /**
         * Barre search
         *
         * @param FilterManager
         * @param SoqModalesManager
         * @constructor
         */
        Controller.$inject = ['FilterManager', 'SoqModalesManager'];
        function Controller(this: any, filterManager: NgFilterManager, soqModalesManager: NgSoqModalesManager) {
            const $ctrl = this;
            let route: string;
            let code: string;

            $ctrl.$onInit = $onInit;
            $ctrl.createFilter = createFilter;
            $ctrl.editFilter = editFilter;
            $ctrl.selectFilter = selectFilter;
            $ctrl.removeKeywords = removeKeywords;
            $ctrl.updateCurrentFilter = updateCurrentFilter;

            /**
             * Initialization method
             */
            function $onInit() {
                switch ($ctrl.type) {
                    case 'VENTES_WALLET_CURRENT' :
                        route = Filter.routes.GET_ETUDE_VENTES;
                        code = Filter.codes.WALLET_CURRENT;
                        $ctrl.filterModale = 'BiensModalFiltersWalletCurrent';
                        $ctrl.helpKeywordsTemplate = 'src/app/legacy/templates/ventes/keywords.help.html';

                        break;

                    case 'LOCATIONS_WALLET_CURRENT' :
                        route = Filter.routes.GET_ETUDE_LOCATIONS;
                        code = Filter.codes.WALLET_CURRENT;
                        $ctrl.filterModale = 'BiensModalFiltersWalletCurrent';
                        $ctrl.helpKeywordsTemplate = 'src/app/legacy/templates/locations/keywords.help.html';

                        break;

                    case 'ESTIMATIONS_WALLET_CURRENT' :
                        route = Filter.routes.GET_ETUDE_ESTIMATIONS;
                        code = Filter.codes.WALLET_CURRENT;
                        $ctrl.filterModale = 'BiensModalFiltersWalletCurrent';
                        $ctrl.helpKeywordsTemplate = 'src/app/legacy/templates/estimations/keywords.help.html';

                        break;

                    case 'DEMANDEURS_WALLET_CURRENT' :
                        route = Filter.routes.GET_ETUDE_DEMANDEURS;
                        code = Filter.codes.WALLET_CURRENT;
                        $ctrl.filterModale = 'DemandeursModalFiltersWalletCurrent';
                        $ctrl.helpKeywordsTemplate = 'src/app/legacy/templates/demandeurs/keywords.help.html';

                        break;

                    default :
                        throw new Error("eskSearchBarre : Type '" + $ctrl.type + "' inconnu");
                }
                $ctrl.loading = true;
                filterManager.getActiveByRouteCode(route, code, true).then(
                    filters => filters as NgFiltersCollection
                ).then(filters => {
                    if (filters.totalItems <= 0) {
                        filters.collection = [filterManager.create({route: route, code: code})] as NgFilter[];
                        filters.current = 1;
                        filters.pages = 1;
                        filters.totalItems = 1;
                    }

                    $ctrl.filters = filters.collection;
                    $ctrl.removeKeywords();
                }).finally(() => $ctrl.loading = false);
            }

            /**
             * Create filter
             */
            function createFilter() {
                $ctrl.editFilter(filterManager.create({
                    route,
                    code,
                    nom: "Mon filtre personnalisé",
                    queryParameters: angular.copy($ctrl.filters[0].queryParameters),
                })).then((filter: NgFilter) => $ctrl.filters.push(filter));
            }

            /**
             * Edit filter
             *
             * @param filter
             */
            function editFilter(filter: NgFilter) {
                return soqModalesManager.open<NgFilter>($ctrl.filterModale, {resolve: {filter}}).then(filter => {
                    if (angular.isObject(filter)) {
                        return filter.save().then(filter => {
                            delete $ctrl.filters[0].fromFilterId;
                            $ctrl.selectFilter(filter);

                            return filter;
                        });
                    }

                    return $ctrl.$onInit();
                });
            }

            /**
             * Select filter
             *
             * @param filter
             */
            function selectFilter(filter: NgFilter) {
                if (filter.id === $ctrl.filters[0].fromFilterId) return;

                $ctrl.removeKeywords(filter);
            }

            /**
             * Remove keywords from filter
             *
             * @param filter
             */
            function removeKeywords(filter: NgFilter) {
                $ctrl.filters[0].queryParameters.keywords = undefined!;
                if (filter?.queryParameters) {
                    filter.queryParameters.keywords = undefined!;
                }

                $ctrl.updateCurrentFilter(filter);
            }

            /**
             * On change on search parameters
             *
             * @param filter
             * @returns {Promise}
             */
            function updateCurrentFilter(filter: NgFilter) {
                if (!$ctrl.filters?.[0]) {
                    return;
                }

                const sort = angular.copy($ctrl.filters[0].queryParameters.sort);
                let filterFromFilterId: NgFilter;

                if (angular.isObject(filter) && angular.isObject(filter.eskManager)) {
                    $ctrl.filters[0].queryParameters = angular.copy(filter.queryParameters);
                    $ctrl.filters[0].fromFilterId = filter.id;
                }

                $ctrl.filters[0].queryParameters.sort = sort;
                filterFromFilterId = find($ctrl.filters, {id: $ctrl.filters[0].fromFilterId});
                $ctrl.filters[0].nom = $ctrl.filters[0].id !== $ctrl.filters[0].fromFilterId && angular.isObject(filterFromFilterId) ? filterFromFilterId.nom : "Mon filtre personnalisé";

                return $ctrl.filters[0]?.save?.().then($ctrl.onChange);
            }
        }
    })(angularJS);
}
