import {NgModule} from '@angular/core';
import {AppProceduresListComponent} from '@features/procedures/list/procedures-list.component';
import {
    DCProcedureSignataireSignComponent
} from '@features/procedures/procedure/signataires/signataire/sign/procedure-signataire.sign.component';
import {DocumentsModule} from '@features/documents/documents.module';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {
    ProcedureSignataireSignActionsMainComponent
} from '@features/procedures/procedure/signataires/signataire/sign/actions/procedure-signataire.sign-actions-main.component';
import {
    DCProcedureProvidersChoiceComponent
} from '@features/procedures/procedure/providers-choice/procedure.providers-choice.component';
import {
    ProcedureSignatairesEditComponent
} from '@features/procedures/procedure/signataires/edit/procedure-signataires.edit.component';
import {ParticipantsModule} from '@features/participants/participants.module';
import {
    ProcedureSignataireDropdownComponent
} from '@features/procedures/procedure/signataires/signataire/dropdown/procedure-signataire.dropdown.component';
import {
    AppProcedureSignataireStatutComponent
} from '@features/procedures/procedure/signataires/signataire/statut/procedure-signataire.statut.component';
import {
    AppProcedurePresentielSignComponent
} from '@features/procedures/procedure/presentiel/procedure.presentiel.sign.component';
import {AppProcedureStatutComponent} from '@features/procedures/procedure/statut/procedure.statut.component';
import {MediasModule} from '@features/medias/medias.module';

@NgModule({
    declarations: [
        AppProcedureSignataireStatutComponent,
        AppProceduresListComponent,
        AppProcedurePresentielSignComponent,
        AppProcedureStatutComponent,
        DCProcedureProvidersChoiceComponent,
        DCProcedureSignataireSignComponent,
        ProcedureSignataireDropdownComponent,
        ProcedureSignatairesEditComponent,
        ProcedureSignataireSignActionsMainComponent,
    ],
    exports: [
        AppProceduresListComponent,
        AppProcedurePresentielSignComponent,
        AppProcedureStatutComponent,
        ProcedureSignatairesEditComponent,
    ],
    imports: [CommonModule, DocumentsModule, MediasModule, ParticipantsModule, SharedModule],
})
export class ProceduresModule {
}
