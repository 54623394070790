import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, from, Observable, Subject} from 'rxjs';
import {distinctUntilKeyChanged, filter, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {UserService} from '@models/users/user/user.service';
import {NgEstimationManager} from '@legacy/app/managers/managers';
import {NgBienDossierCurrent} from '@legacy/app/biens/biens';
import {NgEstimation} from '@legacy/app/managers/ressources';
import {CDossierFilesService} from '@models/dossiers/dossier/files/collection/dossier-files.collection.service';
import {EstimationFactory} from '@models/estimations/estimation/estimation.factory';
import {EstimationNotestimService} from '@models/estimations/estimation/notestim/estimation-notestim.service';
import {RoutesService} from '@shared/routes/routes.service';

@Component({selector: 'layout-estimation', templateUrl: 'layout-estimation.component.html'})
export class AppLayoutEstimationComponent implements OnDestroy, OnInit {
    private _activatedRoute = inject(ActivatedRoute);
    private _cDossierFilesService = inject(CDossierFilesService);
    private _estimationFactory = inject(EstimationFactory);
    private _estimationNotestimService = inject(EstimationNotestimService);
    private _ngInjectorService = inject(NgInjectorService);
    private _routesService = inject(RoutesService);
    private _userService = inject(UserService);
    private _estimationSource = new Subject<NgEstimation>();
    private _estimation$ = this._estimationSource.asObservable();
    private readonly _onDestroy$ = new Subject<void>();

    // Supprimer les injections des anciens manager
    private get ngBienDossierCurrent(): NgBienDossierCurrent {
        return this._ngInjectorService.getService('BienDossierCurrent');
    }

    // Supprimer les injections des anciens manager
    private get ngEstimationManager(): NgEstimationManager {
        return this._ngInjectorService.getService('EstimationManager');
    }

    get estimation$(): Observable<NgEstimation> {
        return this._estimation$;
    }

    ngOnInit(): void {
        combineLatest([this._activatedRoute.params, this._userService.last$]).pipe(
            tap(_ => this._estimationSource.next(undefined!)),
            switchMap(([params, currentUser]): Observable<void> => from(this.ngEstimationManager.initCurrent(+params.estimationId, currentUser))),
            switchMap(_ => this.ngEstimationManager.current$),
            tap(ngEstimation => this.ngBienDossierCurrent.set(ngEstimation)),
            tap(ngEstimation => this._estimationSource.next(ngEstimation)),
            switchMap(() => this.ngEstimationManager.current$),
            filter(ngEstimation => !!ngEstimation),
            // @todo Nécessaire pour éviter 2 appels, à supprimer quand le manager ne sera plus utilisé
            distinctUntilKeyChanged('uuid'),
            map(ngEstimation => this._estimationFactory.ngCreate(ngEstimation)),
            // @todo Simplifier tous ces appels d'initialisation
            tap(estimation => this._cDossierFilesService.initCurrentWithMedia(estimation)),
            tap(estimation => this._estimationNotestimService.initCurrent(estimation)),
            // @todo Vérifier si vraiment utile d'utiliser this._routesService.isNavigationEndUrlWith$() et pas simplement le filtre sur NavigationEnd
            switchMap(estimation => this._routesService.isNavigationEndUrlWith$(estimation.id?.toString()).pipe(tap(hasToInit => {
                if (hasToInit) {
                    this._cDossierFilesService.initCurrentWithMedia(estimation);
                    this._estimationNotestimService.initCurrent(estimation);
                }
            }))),
            takeUntil(this._onDestroy$),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }
}
