import {Component, inject, Input} from '@angular/core';
import {NgBienDossier, NgEstimation, NgLocation, NgVente} from '@legacy/app/managers/ressources';
import {from, Observable, of, switchMap} from 'rxjs';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import {NgDictionariesManager} from '@legacy/app/managers/managers';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {UserService} from '@models/users/user/user.service';
import {map, take, tap} from 'rxjs/operators';
import {ToasterService} from '@shared/toaster/toaster.service';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {Router} from '@angular/router';
import {NgBienDossierCurrent} from '@legacy/app/biens/biens';
import {EstimationService} from '@models/estimations/estimation/estimation.service';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienFactory} from '@models/dossiers/biens/bien/dossier-bien.factory';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {EtudeService} from '@models/etudes/etude/etude.service';
import Etude from '@models/etudes/etude/etude.model';
import {DictionarySelected} from '@features/dictionaries/dictionaries.interfaces';
import {LocationService} from '@models/locations/location/location.service';
import {VenteService} from '@models/ventes/vente/vente.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DossierBienFieldsConst, DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import Estimation from '@models/estimations/estimation/estimation.model';
import {DossierBienFieldsService} from '@models/dossiers/biens/bien/dossier-bien.fields.service';

interface Tab {
    heading: string;
    index: string;
}

@Component({selector: 'app-bien-edit-description', templateUrl: 'bien.edit-description.component.html'})
export class AppBienEditDescriptionComponent {
    // @todo Utilité du typage ?
    static readonly descriptifTabs: Record<string, Tab> = {
        GENERAL: {heading: 'Descriptif général *', index: 'GENERAL'},
        MANDAT: {heading: 'Mandat', index: 'MANDAT'},
        AFFICHETTE: {heading: 'Affichette', index: 'AFFICHETTE'},
        SITE_INTERNET: {heading: 'Sites Internet', index: 'SITE_INTERNET'},
        JOURNAUX_PAPIER: {heading: 'Journaux papier', index: 'JOURNAUX_PAPIER'},
    };
    static readonly SET_TITRE_DESCRIPTIF = 'setTitreDescriptif';
    private _dictionaryItemFactory = inject(DictionaryItemFactory);
    private _dictionaryItemService = inject(DictionaryItemService);
    private _dossierBienFactory = inject(DossierBienFactory);
    private _dossierBienFieldsService = inject(DossierBienFieldsService);
    private _estimationService = inject(EstimationService);
    private _etudeService = inject(EtudeService);
    private _locationService = inject(LocationService);
    private _ngInjectorService = inject(NgInjectorService);
    private _router = inject(Router);
    private _toasterService = inject(ToasterService);
    private _userService = inject(UserService);
    private _venteService = inject(VenteService);
    private _bienDossier!: ADossierBien;
    private _bienDossierType!: DictionaryItem;
    private _bienNature!: DictionaryItem;
    private _descriptifTab = AppBienEditDescriptionComponent.descriptifTabs.GENERAL;
    private _descriptifTabs: Tab[] = [];
    private _loaderTitreDescriptif = false;
    private _ngBienDossier!: NgBienDossier;
    private _ngBienDossierNotLocation!: NgEstimation | NgVente;
    private _ngLocation!: NgLocation;
    private _superficieLabel = DictionaryItemService.superficieLabel.DEFAULT;

    // Supprimer les injections des anciens manager
    private get ngBienDossierCurrent(): NgBienDossierCurrent {
        return this._ngInjectorService.getService('BienDossierCurrent');
    }

    // Supprimer les injections des anciens manager
    private get ngDictionariesManager(): NgDictionariesManager {
        return this._ngInjectorService.getService('DictionariesManager');
    }

    // Supprimer les injections des anciens manager
    private get ngSoqSweetAlert(): NgSoqSweetAlert {
        return this._ngInjectorService.getService('SoqSweetAlert');
    }

    get bienDossierType(): DictionaryItem {
        return this._bienDossierType;
    }

    get bienNature(): DictionaryItem {
        return this._bienNature;
    }

    get currentEtude$(): Observable<Etude> {
        return this._etudeService.last$;
    }

    get descriptifTab(): Tab {
        return this._descriptifTab;
    }

    set descriptifTab(value: Tab) {
        this._descriptifTab = value;
    }

    get descriptifTabs(): Tab[] {
        return this._descriptifTabs;
    }

    get DICTIONARY_NAMES_NATURES(): string {
        return Dictionary.names.NATURES;
    }

    get DOSSIER_BIEN_BROUILLON(): string {
        return ADossierBien.abstractBienStatuts.BROUILLON;
    }

    get DOSSIER_BIEN_TYPES(): typeof DossierBienTypesConst {
        return DossierBienTypesConst;
    }

    get estimation(): Estimation {
        return this._bienDossier as Estimation;
    }

    get FIELDS(): Record<string, string> {
        return DossierBienFieldsConst;
    }

    get loaderTitreDescriptif(): boolean {
        return this._loaderTitreDescriptif;
    }

    get ngBienDossier(): NgBienDossier {
        return this._ngBienDossier;
    }

    @Input()
    set ngBienDossier(value: NgBienDossier) {
        this._ngBienDossier = value;
        this._ngBienDossierNotLocation = this._ngBienDossier as NgEstimation | NgVente;
        this._ngLocation = this._ngBienDossier as NgLocation;
        this._bienDossier = this._dossierBienFactory.ngCreate(this._ngBienDossier);
        this.setDescriptifTabs();
        if (this._ngBienDossier.bien.nature) {
            this._bienNature = this._dictionaryItemFactory.ngCreate(this._ngBienDossier.bien.nature);
        } else {
            this._bienNature = undefined!;
        }

        this._superficieLabel = this._dictionaryItemService.getOneSuperficieLabel(this._bienNature);
        if (this._ngBienDossierNotLocation.type) {
            this._bienDossierType = this._dictionaryItemFactory.ngCreate(this._ngBienDossierNotLocation.type);
        } else {
            this._bienDossierType = undefined!;
        }
    }

    get ngLocation(): NgLocation {
        return this._ngLocation;
    }

    get superficieLabel(): string {
        return this._superficieLabel;
    }

    onChangeNature(bienNature: DictionaryItem): void {
        this.ngBienDossierCurrent.onChangeNature();
        this._bienNature = bienNature;
        this._ngBienDossier.bien.nature = this.ngDictionariesManager.createFromNg2(this._bienNature);
        this._superficieLabel = this._dictionaryItemService.getOneSuperficieLabel(this._bienNature);
        if (this._ngBienDossier._esk.typeDossier === DossierBienTypesConst.VENTE) {
            (this._ngBienDossier as NgVente).operatePricing();
        }
    }

    onSelectBienDossierType(bienDossierType: DictionaryItem): void {
        let hasRole$ = of(true);
        let typeDe = 'de bien';

        if (!bienDossierType) {
            return;
        }

        if (this.ngBienDossier._esk.typeDossier === DossierBienTypesConst.ESTIMATION) {
            typeDe = 'd\'estimation';
        } else if (this.ngBienDossier._esk.typeDossier === DossierBienTypesConst.VENTE) {
            typeDe = 'de vente';
        }

        const title = 'Changement de type ' + typeDe;

        if (this.ngBienDossier._esk.typeDossier === DossierBienTypesConst.ESTIMATION) {
            hasRole$ = this._userService.last$.pipe(
                map(currentUser => {
                    if (bienDossierType.code === Estimation.types.EVALUATION) {
                        return currentUser.hasRoleGrpEstimationEvaluation();
                    } else if (bienDossierType.code === Estimation.types.AVIS_VALEUR) {
                        return currentUser.hasRoleGrpEstimationAvisValeur();
                    }

                    return false;
                }),
                switchMap(hasRole => {
                    if (hasRole) {
                        return of(true);
                    }

                    const rapport = bienDossierType.code === Estimation.types.EVALUATION ? 'rapport d\'évaluation' : 'avis de valeur';

                    return from(this.ngSoqSweetAlert.warningConfirmation(
                        title,
                        'Vous n\'avez pas les droits nécessaires pour créer un ' + rapport + '.',
                        {confirmButtonText: 'En savoir plus'}
                    ).then(() => this._router.navigateByUrl('/app/estimations/informations'), () => false))
                        .pipe(map(_ => false));
                }),
            );
        }

        hasRole$.pipe(
            switchMap(hasRole => {
                if (!hasRole || this._ngBienDossierNotLocation.type.code === bienDossierType.code) {
                    return of(false);
                }

                if (!this.ngBienDossier.uuid) {
                    this._toasterService.warning(title, 'Le changement de type ' + typeDe + ' entraînera une perte définitive des informations liées à l\'ancien type enregistré.');

                    return of(true);
                }

                return from(this.ngSoqSweetAlert.warningConfirmation(
                    'Êtes-vous sûr de vouloir changer le type ' + typeDe + ' du bien ?',
                    'Le changement de type ' + typeDe + ' de votre bien en ' + bienDossierType.label
                    + ' entraînera une perte définitive des informations liées à l\'ancien type enregistré (' + this._ngBienDossierNotLocation.type.label + ').',
                    {confirmButtonText: 'Oui, modifier le type ' + typeDe}
                ).then(() => {
                    this._toasterService.info(title, 'Pensez à enregistrer pour prendre en compte le changement de type ' + typeDe + '.');

                    return true;
                }, () => {
                    this._bienDossierType = this._dictionaryItemFactory.ngCreate(this._ngBienDossierNotLocation.type);

                    return false;
                }));
            }),
            take(1),
        ).subscribe(needToSave => {
            if (needToSave) {
                this._bienDossierType = bienDossierType;
                this._ngBienDossierNotLocation.type = this.ngDictionariesManager.createFromNg2(this._bienDossierType);
                this._ngBienDossierNotLocation.sanitizeOnChangeType();
            }
        });
    }

    onSelectBienNature(bienNatureSelected: DictionarySelected): void {
        const bienNature = bienNatureSelected as DictionaryItem;

        if (this.ngBienDossier.bien.nature.code === bienNature.code) {
            return;
        }

        if (!this.ngBienDossier.uuid) {
            this._toasterService.warning('Changement de nature',
                'Le changement de nature entraînera une perte définitive des informations liées à l\'ancienne nature enregistrée.');
            this.onChangeNature(bienNature);

            return;
        }

        this.ngSoqSweetAlert.warningConfirmation('Êtes-vous sûr de vouloir changer la nature du bien ?',
            'Le changement de nature de votre bien en ' + bienNature.label
            + ' entraînera une perte définitive des informations liées à l\'ancienne nature enregistrée (' + this.ngBienDossier.bien.nature.label + ').',
            {confirmButtonText: 'Oui, modifier la nature'})
            .then(() => this.onChangeNature(bienNature))
            .catch(() => this._bienNature = this._dictionaryItemFactory.ngCreate(this._ngBienDossier.bien.nature))
            .finally(() => {
                if (this._ngBienDossier._esk.typeDossier === DossierBienTypesConst.ESTIMATION) {
                    this._estimationService.askToBeSaved();
                } else if (this._ngBienDossier._esk.typeDossier === DossierBienTypesConst.LOCATION) {
                    this._locationService.askToBeSaved();
                } else if (this._ngBienDossier._esk.typeDossier === DossierBienTypesConst.VENTE) {
                    this._venteService.askToBeSaved();
                }
            });
    }

    setDescriptifTabs(): void {
        let descriptifTabs$ = of(undefined!) as unknown as Observable<void>;

        this._descriptifTabs = [AppBienEditDescriptionComponent.descriptifTabs.GENERAL];
        if (this._ngBienDossier._esk.typeDossier !== DossierBienTypesConst.ESTIMATION) {
            this._descriptifTabs.push(AppBienEditDescriptionComponent.descriptifTabs.MANDAT);
            this._descriptifTabs.push(AppBienEditDescriptionComponent.descriptifTabs.AFFICHETTE);
            descriptifTabs$ = this._etudeService.last$.pipe(
                map(currentEtude => {
                    if (currentEtude.hasWebsite()) {
                        this._descriptifTabs.push(AppBienEditDescriptionComponent.descriptifTabs.SITE_INTERNET);
                    }

                    if (currentEtude.hasNewspaper()) {
                        this._descriptifTabs.push(AppBienEditDescriptionComponent.descriptifTabs.JOURNAUX_PAPIER);
                    }
                }),
            );
        }

        descriptifTabs$.pipe(take(1)).subscribe(_ => this._descriptifTab = this._descriptifTabs[0]);
    }

    setTitreDescriptif(): void {
        this._loaderTitreDescriptif = true;
        this._ngBienDossier._esk.setTitreDescriptif!.need = true;
        if (this._ngBienDossier._esk.typeDossier === DossierBienTypesConst.ESTIMATION) {
            this._estimationService.askToBeSaved(AppBienEditDescriptionComponent.SET_TITRE_DESCRIPTIF);
        } else if (this._ngBienDossier._esk.typeDossier === DossierBienTypesConst.LOCATION) {
            this._locationService.askToBeSaved(AppBienEditDescriptionComponent.SET_TITRE_DESCRIPTIF);
        } else if (this._ngBienDossier._esk.typeDossier === DossierBienTypesConst.VENTE) {
            this._venteService.askToBeSaved(AppBienEditDescriptionComponent.SET_TITRE_DESCRIPTIF);
        }
        this._ngBienDossier._esk.setTitreDescriptif!.exec$!.pipe(
            tap(_ => this._descriptifTab = AppBienEditDescriptionComponent.descriptifTabs.GENERAL),
            take(1),
        ).subscribe(_ => this._loaderTitreDescriptif = false);
    }

    setSuperficie(): void {
        if (!this._ngBienDossier.bien._esk.surfacePiecesHabitables) {
            return;
        }

        const superficie = Math.round(this._ngBienDossier.bien._esk.surfacePiecesHabitables * 100) / 100;

        this._ngBienDossier.bien.superficie = superficie.toString().replace('.', ',') as unknown as number;
    }

    showFieldForBien(field: string): boolean {
        return this._dossierBienFieldsService.isEditionVisible(this._bienDossier, field);
    }
}
