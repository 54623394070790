<div class="tw-max-w-[41rem] tw-mx-auto tw-rounded-lg tw-bg-orange-50 tw-p-4 tw-space-y-4">
  <h3 class="tw-text-center tw-font-semi-bold tw-text-gray-900">
    <!-- 17px pour 25px -->
    Accès à <app-noteo-logo-notestim class="tw-h-[17px]"/>
  </h3>

  <div class="tw-text-base tw-text-gray-600">
    Il manque des informations à votre bien en estimation afin d'accéder à
    <!-- 14px pour 20px -->
    <app-noteo-logo-notestim class="tw-h-[14px]"/>.

    <div class="tw-pl-12">
      <ul>
        @if (!estimation.bien.commune) {
          <li>- La commune</li>
        }

        @if (!estimation.bien.codePostal) {
          <li>- Le code postal</li>
        }

        @if (!estimation.bien.hasLocalisation()) {
          <li>- L'adresse</li>
        }

        @if (estimation.bien.superficie <= 0) {
          <li>- La {{labelSuperficie | lowercase}}</li>
        }
      </ul>
    </div>
  </div>

  <p class="tw-text-center tw-text-sm">
    Complétez les informations dans la partie
    <a [routerLink]="'/app/estimations/' + estimation.id.toString() + '/edit/general'" class="tw-font-semibold">
      "Fiche" de votre bien en estimation
    </a>.
  </p>
</div>
