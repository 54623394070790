import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgEskBox} from '@legacy/app/eskimmo/eskimmo';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import User from '@models/users/user/user.model';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {NgFilter} from '@legacy/app/managers/ressources';
import {UserService} from '@models/users/user/user.service';
import {UserFactory} from '@models/users/user/user.factory';
import {Observable, combineLatest, switchMap, of} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import Filter from '@models/filters/filter/filter.model';
import {FilterFactory} from '@models/filters/filter/filter.factory';
import {ModalService} from '@shared/modal/modal.service';

export default function getBiensModalFiltersWalletCurrentCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('BiensModalFiltersWalletCurrentCtrl', Controller);

        /**
         * Controller to manage filters on wallet current
         *
         * @param filter
         * @param Ng2UserService
         * @param $uibModalInstance
         * @param SoqSweetAlert
         * @param $translate
         * @param EskBox
         * @param Ng2EtudeService
         * @param Ng2UserFactory
         * @param Ng2DictionaryItemService
         * @param Ng2FilterFactory
         * @param Ng2ModalesService
         */
        Controller.$inject = ['filter', 'Ng2UserService', '$uibModalInstance', 'SoqSweetAlert', '$translate', 'EskBox', 'Ng2EtudeService', 'Ng2UserFactory', 'Ng2DictionaryItemService', 'Ng2FilterFactory', 'Ng2ModalService'];
        function Controller(this: any,
                            ngFilter: NgFilter,
                            ng2UserService: UserService,
                            $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                            soqSweetAlert: NgSoqSweetAlert,
                            $translate: angular.translate.ITranslateService,
                            eskBox: NgEskBox,
                            ng2EtudeService: EtudeService,
                            ng2UserFactory: UserFactory,
                            ng2DictionaryItemService: DictionaryItemService,
                            ng2FilterFactory: FilterFactory,
                            ng2ModalService: ModalService) {
            const $ctrl = this;

            $ctrl.onSelectEstimationStatuts = onSelectEstimationStatuts;
            $ctrl.onSelectEstimationTypes = onSelectEstimationTypes;
            $ctrl.onSelectLocationStatuts = onSelectLocationStatuts;
            $ctrl.onSelectMandatVenteTypes = onSelectMandatVenteTypes;
            $ctrl.onSelectNatures = onSelectNatures;
            $ctrl.onSelectNotaires = onSelectNotaires;
            $ctrl.onSelectResponsables = onSelectResponsables;
            $ctrl.onSelectVenteStatuts = onSelectVenteStatuts;
            $ctrl.onSelectVenteTypes = onSelectVenteTypes;
            $ctrl.remove = remove;
            $ctrl.save = save;
            $ctrl.ESTIMATIONS_STATUTS = Dictionary.names.ESTIMATION_STATUTS;
            $ctrl.ESTIMATION_TYPES = Dictionary.names.ESTIMATION_TYPES;
            $ctrl.FILTER_ROUTES = Filter.routes;
            $ctrl.listMandatsEcheance = eskBox.getListMandatsEcheance();
            $ctrl.LOCATIONS_STATUTS = Dictionary.names.LOCATION_STATUTS;
            $ctrl.NATURES = Dictionary.names.NATURES;
            $ctrl.options = {mandatsEcheance: {placeholder: "Sélectionner une échéance", name: 'mandatsEcheance'}};
            $ctrl.VENTE_MANDAT_TYPES = Dictionary.names.VENTE_MANDAT_TYPES;
            $ctrl.VENTE_TYPES = Dictionary.names.VENTE_TYPES;
            $ctrl.VENTE_STATUTS = Dictionary.names.VENTE_STATUTS;
            $ctrl.filter = angular.copy(ngFilter);
            $ctrl.roleNotaire = User.roles.NOTAIRE;
            $ctrl.usersRoles = [User.roles.NEGOCIATEUR, User.roles.NOTAIRE];
            ng2EtudeService.last$.subscribe(currentEtude => $ctrl.currentEtude = currentEtude);
            ng2UserService.last$.subscribe(currentUser => $ctrl.hasRoleEvaluation = currentUser.hasRoleGrpEstimationEvaluation());

            if (!$ctrl.filter.queryParameters) {
                $ctrl.filter.queryParameters = {};
            }

            if (!$ctrl.filter.queryParameters.nature) {
                $ctrl.filter.queryParameters.nature = {};
            }

            if (!$ctrl.filter.queryParameters.notaire || Array.isArray($ctrl.filter.queryParameters.notaire)) {
                $ctrl.filter.queryParameters.notaire = {};
            }

            if (!$ctrl.filter.queryParameters.responsableDossier) {
                $ctrl.filter.queryParameters.responsableDossier = {};
            }

            const notaires$: Observable<User>[] = ($ctrl.filter.queryParameters?.notaire?.values || [])
                .filter(userId => !!userId)
                .map(userId => ng2UserFactory.get$(userId));
            const responsableDossiers$: Observable<User>[] = ($ctrl.filter.queryParameters?.responsableDossier?.values || [])
                .filter(userId => !!userId)
                .map(userId => ng2UserFactory.get$(userId));

            if (notaires$?.length >= 0) {
                combineLatest(notaires$).pipe(take(1))
                    .subscribe(notaires => $ctrl.notaires = notaires);
            } else {
                $ctrl.notaires = [];
            }

            if (responsableDossiers$?.length >= 0) {
                combineLatest(responsableDossiers$).pipe(take(1))
                    .subscribe(responsableDossiers => $ctrl.responsableDossiers = responsableDossiers);
            } else {
                $ctrl.responsableDossiers = [];
            }

            if ($ctrl.filter.queryParameters?.mandat?.type?.values?.length >= 0) {
                $ctrl.mandatVenteTypes = $ctrl.filter.queryParameters.mandat.type.values.map(dictionaryItem => ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_MANDAT_TYPES, dictionaryItem));
            } else {
                $ctrl.mandatVenteTypes = [];
            }

            if ($ctrl.filter.queryParameters?.nature?.values?.length >= 0) {
                $ctrl.natures = $ctrl.filter.queryParameters.nature.values.map(dictionaryItem => ng2DictionaryItemService.getByCode(Dictionary.names.NATURES, dictionaryItem));
            } else {
                $ctrl.natures = [];
            }

            if ($ctrl.filter.queryParameters?.typeEstimation?.values?.length >= 0) {
                $ctrl.estimationTypes = $ctrl.filter.queryParameters.typeEstimation.values.map(dictionaryItem => ng2DictionaryItemService.getByCode(Dictionary.names.ESTIMATION_TYPES, dictionaryItem));
            } else {
                $ctrl.estimationTypes = [];
            }

            if ($ctrl.filter.queryParameters?.statut?.values?.length >= 0) {
                $ctrl.estimationStatuts = $ctrl.filter.queryParameters.statut.values.map(dictionaryItem => ng2DictionaryItemService.getByCode(Dictionary.names.ESTIMATION_STATUTS, dictionaryItem));
                $ctrl.locationStatuts = $ctrl.filter.queryParameters.statut.values.map(dictionaryItem => ng2DictionaryItemService.getByCode(Dictionary.names.LOCATION_STATUTS, dictionaryItem));
                $ctrl.venteStatuts = $ctrl.filter.queryParameters.statut.values.map(dictionaryItem => ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_STATUTS, dictionaryItem));
            } else {
                $ctrl.estimationStatuts = [];
                $ctrl.locationStatuts = [];
                $ctrl.venteStatuts = [];
            }

            if ($ctrl.filter.queryParameters?.typeVente?.values?.length >= 0) {
                $ctrl.venteTypes = $ctrl.filter.queryParameters.typeVente.values.map(dictionaryItem => ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_TYPES, dictionaryItem));
            } else {
                $ctrl.venteTypes = [];
            }

            function onSelectEstimationStatuts(estimationStatuts: DictionaryItem[] = []) {
                $ctrl.estimationStatuts = estimationStatuts;
                $ctrl.filter.queryParameters.statut.values = estimationStatuts.filter(estimationStatut => !!estimationStatut).map(estimationStatut => estimationStatut.code);
            }

            function onSelectEstimationTypes(estimationTypes: DictionaryItem[] = []) {
                $ctrl.estimationTypes = estimationTypes;
                $ctrl.filter.queryParameters.typeEstimation.values = estimationTypes.filter(estimationType => !!estimationType).map(estimationType => estimationType.code);
            }

            function onSelectLocationStatuts(locationStatuts: DictionaryItem[] = []) {
                $ctrl.locationStatuts = locationStatuts;
                $ctrl.filter.queryParameters.statut.values = locationStatuts.filter(locationStatut => !!locationStatut).map(locationStatut => locationStatut.code);
            }

            function onSelectMandatVenteTypes(mandatVenteTypes: DictionaryItem[] = []) {
                $ctrl.mandatVenteTypes = mandatVenteTypes;
                if (!$ctrl.filter.queryParameters.mandat.type) {
                    $ctrl.filter.queryParameters.mandat.type = {};
                }

                $ctrl.filter.queryParameters.mandat.type.values = mandatVenteTypes.filter(mandatVenteType => !!mandatVenteType).map(mandatVenteType => mandatVenteType.code);
            }

            function onSelectNatures(natures: DictionaryItem[] = []) {
                $ctrl.natures = natures;
                $ctrl.filter.queryParameters.nature.values = natures.filter(nature => !!nature).map(nature => nature.code);
            }

            function onSelectNotaires(users: User[] = []) {
                $ctrl.notaires = users;
                $ctrl.filter.queryParameters.notaire.values = users.filter(user => !!user).map(user => user.id);
            }

            function onSelectResponsables(users: User[] = []) {
                $ctrl.responsableDossiers = users;
                $ctrl.filter.queryParameters.responsableDossier.values = users.filter(user => !!user).map(user => user.id);
            }

            function onSelectVenteStatuts(venteStatuts: DictionaryItem[] = []) {
                $ctrl.venteStatuts = venteStatuts;
                $ctrl.filter.queryParameters.statut.values = venteStatuts.filter(venteStatut => !!venteStatut).map(venteStatut => venteStatut.code);
            }

            function onSelectVenteTypes(venteTypes: DictionaryItem[] = []) {
                $ctrl.venteTypes = venteTypes;
                $ctrl.filter.queryParameters.typeVente.values = venteTypes.filter(venteType => !!venteType).map(venteType => venteType.code);
            }

            /**
             * Remove filter and close modal
             */
            function remove() {
                ng2ModalService.openConfirmation$({
                    buttonConfirmationLabel: 'Supprimer',
                    question: $translate.instant('remove.confirm.MESSAGE', {ceData: 'ce filtre'}),
                    title: $translate.instant('remove.confirm.TITLE'),
                    status: ModalService.status.DANGER,
                }).pipe(
                    switchMap(response => {
                        if (!response) {
                            return of(null);
                        }

                        $ctrl.loading = 'REMOVE';

                        return ng2FilterFactory.delete$(ng2FilterFactory.ngCreate($ctrl.filter)).pipe(
                            tap(() => $uibModalInstance.close()),
                        );
                    }),
                    take(1),
                ).subscribe({complete: () => $ctrl.loading = undefined});
            }

            /**
             * Save filter and close modal
             */
            function save() {
                if (!$ctrl.editForm.$submitted || !$ctrl.editForm.$valid) {
                    soqSweetAlert.warningMessage($translate.instant("formulaire.invalid.TITLE"), $translate.instant("formulaire.invalid.MESSAGE"));
                    return;
                }

                $uibModalInstance.close(ngFilter?.extend?.($ctrl.filter));
            }
        }
    })(angularJS);
}
