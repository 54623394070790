import {inject, Injectable} from '@angular/core';
import {from, Observable, of, switchMap} from 'rxjs';
import Estimation from '@models/estimations/estimation/estimation.model';
import {EstimationNotestimService} from '@models/estimations/estimation/notestim/estimation-notestim.service';
import {map, take} from 'rxjs/operators';
import EstimationNotestim from '@models/estimations/estimation/notestim/estimation-notestim.model';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '@models/users/user/user.service';
import {IEstimationRapportReadNotestimUpdateDataObject} from '@features/estimations/estimations.interfaces';
import {EstimationService} from '@models/estimations/estimation/estimation.service';

@Injectable({providedIn: 'root'})
export class EstimationRapportService {
    static readonly ROUTING = {EDIT: 'edit', READ: 'read', WRITE: 'redaction'};
    static readonly STATUTS = {EDIT: 'EDIT', READ: 'READ', WRITE: 'WRITE'};
    private _estimationService = inject(EstimationService);
    private _estimationNotestimService = inject(EstimationNotestimService);
    private _router = inject(Router);
    private _userService = inject(UserService);

    get estimationNotestim$(): Observable<EstimationNotestim> {
        return this._estimationNotestimService.current$;
    }

    getStatut$(estimation: Estimation): Observable<string> {
        return this._userService.last$.pipe(map(currentUser => {
            if (currentUser.hasRoleEstimationNotestimV2() && estimation.couldUseNotestimV2()) {
                return EstimationRapportService.STATUTS.READ;
            }

            return estimation.rapportId ? EstimationRapportService.STATUTS.WRITE : EstimationRapportService.STATUTS.EDIT;
        }));
    }

    init(estimation: Estimation): void {
        this._estimationNotestimService.initCurrent(estimation);
    }

    redirect$(estimation: Estimation, currentUrl: string, activatedRoute: ActivatedRoute): Observable<boolean> {
        return this.getStatut$(estimation).pipe(
            take(1),
            switchMap(statut => {
                if (currentUrl.endsWith(EstimationRapportService.ROUTING[statut])) {
                    return of(false);
                }

                return from(this._router.navigateByUrl(this._router.createUrlTree(
                    [`../${EstimationRapportService.ROUTING[statut]}`],
                    {relativeTo: activatedRoute},
                ).toString()));
            }),
        );
    }

    updateFromNotestimData$(estimation: Estimation, dataObject: IEstimationRapportReadNotestimUpdateDataObject): Observable<Estimation> {
        estimation.valeurVenale = dataObject.valeurVenale;

        return this._estimationService.saveAndUpdateCurrent$(estimation);
    }
}
