@if (media$ | async; as media) {
  @if (options.enabledIcon) {
    <div class="tw-flex tw-items-center">
      <fa-icon [icon]="['far', 'file']"/>
      <div class="tw-pl-2 tw-text-sm tw-font-medium tw-truncate" title="{{media.title}}">{{ media.title }}</div>
    </div>
  } @else {
    <!-- @todo Vérifier "ellipsis" -->
    <div class="ellipsis">{{ media.title }}</div>
  }
} @else {
  <app-loader/>
}
