import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, Subject, switchMap} from 'rxjs';
import {filter, map, startWith, take, takeUntil, tap} from 'rxjs/operators';
import {CDossierFilesFactory} from '@models/dossiers/dossier/files/collection/dossier-files.collection.factory';
import CDossierFiles from '@models/dossiers/dossier/files/collection/dossier-files.collection.model';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import {DossierFileService} from '@models/dossiers/dossier/files/file/dossier-file.service';
import DossierFile from '@models/dossiers/dossier/files/file/dossier-file.model';
import {DossierFileFactory} from '@models/dossiers/dossier/files/file/dossier-file.factory';
import {ModalService} from '@shared/modal/modal.service';

@Injectable({providedIn: 'root'})
export class CDossierFilesService {
    private _cDossierFilesFactory = inject(CDossierFilesFactory);
    private _dossierFileFactory = inject(DossierFileFactory);
    private _dossierFileService = inject(DossierFileService);
    private _modalService = inject(ModalService);
    private _currentSource = new BehaviorSubject<CDossierFiles>(undefined!);
    private readonly _endCurrentSubject = new Subject<void>();

    get current$(): Observable<CDossierFiles> {
        return this._currentSource.asObservable();
    }

    addNext$(cDossierFiles: CDossierFiles): Observable<CDossierFiles> {
        return this._cDossierFilesFactory.getNext$(cDossierFiles).pipe(
            switchMap(newCDossierFiles => this.attachMedias$(newCDossierFiles)),
            tap(newCDossierFiles => cDossierFiles.updateNext(newCDossierFiles)),
            map(_ => cDossierFiles),
        );
    }

    addNextToCurrent$(): Observable<CDossierFiles> {
        return this.current$.pipe(
            take(1),
            switchMap(cDossierFiles => this.addNext$(cDossierFiles)),
        );
    }

    attachMedias$(cDossierFiles: CDossierFiles): Observable<CDossierFiles> {
        if (cDossierFiles.total <= 0) {
            return of(cDossierFiles);
        }

        return combineLatest(cDossierFiles.results.map(dossierFile => this._dossierFileService.attachMedia$(dossierFile))).pipe(map(_ => cDossierFiles));
    }

    deleteFromCurrent$(dossier: ADossier, dossierFilesToRemove: DossierFile[] = []): Observable<boolean> {
        if (dossierFilesToRemove.length <= 0) {
            return of(false);
        }

        return this._modalService.openConfirmation$({
            buttonConfirmationLabel: 'Supprimer',
            question: 'Voulez-vous vraiment supprimer ' + (dossierFilesToRemove.length > 1 ? ('ces ' + dossierFilesToRemove.length.toString() + ' documents') : 'ce document') + ' ?',
            title: 'Suppression ' + (dossierFilesToRemove.length > 1 ? ('de ' + dossierFilesToRemove.length.toString() + ' documents') : 'd\'un document'),
            status: ModalService.status.DANGER,
        }).pipe(switchMap(isAccepted => {
            if (!isAccepted) {
                return of(false);
            }

            return this.current$.pipe(
                take(1),
                switchMap(cDossierFiles => combineLatest(dossierFilesToRemove.map(dossierFileToRemove => this._dossierFileFactory.delete$(dossier, dossierFileToRemove).pipe(
                    tap(_ => cDossierFiles.removeResult(dossierFile => dossierFile.uuid === dossierFileToRemove.uuid)),
                )))),
                map(_ => true),
            );
        }));
    }

    getAll$(dossier: ADossier): Observable<CDossierFiles> {
        return this._cDossierFilesFactory.get$(dossier).pipe(
            switchMap(cDossierFiles => this.updateAll$(cDossierFiles)),
            switchMap(cDossierFiles => this.attachMedias$(cDossierFiles)),
        );
    }

    getCurrentTotal$(): Observable<number> {
        return this.current$.pipe(
            filter(cDossierFiles => !!cDossierFiles),
            switchMap(cDossierFiles => cDossierFiles.updated$.pipe(
                startWith(undefined),
                switchMap(_ => this.getOneByLink$(cDossierFiles.links.self))),
            ),
            map(cDossierFiles => cDossierFiles.total),
        );
    }

    getOneByLink$(link?: string): Observable<CDossierFiles> {
        if (!link) {
            return of(this._cDossierFilesFactory.createVirgin());
        }

        return this._cDossierFilesFactory.getByLink$(link);
    }

    getOneByLinkWithMedia$(link: string): Observable<CDossierFiles> {
        return this.getOneByLink$(link).pipe(switchMap(cDossierFiles => this.attachMedias$(cDossierFiles)));
    }

    initCurrentWithMedia(dossier: ADossier): void {
        this._endCurrentSubject.next();
        this._currentSource.next(undefined!);
        if (!dossier.isNewOrIdNullish() && dossier.interne) {
            this._cDossierFilesFactory.get$(dossier).pipe(
                switchMap(cDossierFiles => this.attachMedias$(cDossierFiles)),
                takeUntil(this._endCurrentSubject),
            ).subscribe(cDossierFiles => this._currentSource.next(cDossierFiles));
        } else {
            this._currentSource.next(this._cDossierFilesFactory.createVirgin());
        }
    }

    // @todo Trouver un nom plus approprié (celui-ci donne l'impression d'envoyer les modifications à l'API et modifier pour les autres collections
    updateAll$(cDossierFiles: CDossierFiles): Observable<CDossierFiles> {
        if (cDossierFiles.links.next) {
            return this.addNext$(cDossierFiles).pipe(
                switchMap(newCDossierFiles => this.updateAll$(newCDossierFiles)),
            );
        }

        return of(cDossierFiles);
    }
}
