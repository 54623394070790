import {inject, NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, RouterModule, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
import {EskimmoModule} from '@shared/angularJS/eskimmo.module';
import {SharedModule} from '@shared/shared.module';
import {AppLayoutConfigurationComponent} from '@core/routing/layout/configuration/layout-configuration.component';
import {
    AppLayoutConfigurationDocumentsFooterComponent
} from '@core/routing/layout/configuration/documents/footer/layout-configuration-documents-footer.component';
import {ConfigurationActionsMainComponent} from '@features/configuration/actions/configuration-actions-main.component';
import {
    AppLayoutConfigurationDocumentsHeaderComponent
} from '@core/routing/layout/configuration/documents/header/layout-configuration-documents-header.component';
import {
    AppLayoutConfigurationDossiersComponent
} from '@core/routing/layout/configuration/dossiers/layout-configuration-dossiers.component';
import {
    AppLayoutConfigurationEtudeComponent
} from '@core/routing/layout/configuration/etude/layout-configuration-etude.component';
import {
    AppLayoutConfigurationNetworkComponent
} from '@core/routing/layout/configuration/network/layout-configuration-network.component';
import {HasRolesGuard} from '@core/routing/guards/has-roles/has-roles-guard.service';
import {IsEnablableModulesGuard} from '@core/routing/guards/is-enablable-modules/is-enablable-modules-guard.service';
import Etude from '@models/etudes/etude/etude.model';
import User from '@models/users/user/user.model';
import {
    NgEskimmoConfigurationPasserellesComponent
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-configuration-passerelles.component';
import {FeaturesModule} from '@features/features.module';
import {
    AppLayoutConfigurationNotestimComponent
} from '@core/routing/layout/configuration/notestim/layout-configuration-notestim.component';

const routes: Routes = [
    {
        component: AppLayoutConfigurationComponent,
        path: '',
        children: [
            {
                path: 'documents',
                children: [
                    {
                        component: AppLayoutConfigurationDocumentsFooterComponent,
                        data: {
                            actions: {
                                main: {
                                    component: ConfigurationActionsMainComponent,
                                    data: {type: 'footer'},
                                },
                            },
                        },
                        path: 'footer',
                    },
                    {
                        component: AppLayoutConfigurationDocumentsHeaderComponent,
                        data: {
                            actions: {
                                main: {
                                    component: ConfigurationActionsMainComponent,
                                    data: {type: 'header'},
                                },
                            },
                        },
                        path: 'header',
                    },
                    // Wildcard pour /app/configuration/documents
                    {path: '**', pathMatch: 'full', redirectTo: 'header'},
                ],
            },
            {
                component: AppLayoutConfigurationDossiersComponent,
                data: {
                    actions: {
                        main: {
                            component: ConfigurationActionsMainComponent,
                            data: {type: 'dossiers'},
                        },
                    },
                },
                path: 'dossiers',
            },
            {
                component: AppLayoutConfigurationEtudeComponent,
                data: {
                    actions: {
                        main: {component: ConfigurationActionsMainComponent, data: {type: 'etude'}},
                    },
                },
                path: 'etude',
            },
            {
                component: AppLayoutConfigurationNetworkComponent,
                canActivate: [
                    (activatedRouteSnapshot: ActivatedRouteSnapshot) => inject(HasRolesGuard).canActivate(activatedRouteSnapshot),
                    (activatedRouteSnapshot: ActivatedRouteSnapshot) => inject(IsEnablableModulesGuard).canActivate(activatedRouteSnapshot),
                ],
                data: {
                    enablableModules: [Etude.enablableModules.NETWORK.code],
                    roles: [User.roles.GRP_VENTE],
                },
                path: 'network',
            },
            {
                component: AppLayoutConfigurationNotestimComponent,
                canActivate: [
                    (activatedRouteSnapshot: ActivatedRouteSnapshot) => inject(HasRolesGuard).canActivate(activatedRouteSnapshot),
                ],
                data: {roles: [User.roles.ESTIMATION_NOTESTIM_V2]},
                path: 'notestim',
            },
            {
                component: NgEskimmoConfigurationPasserellesComponent,
                canActivate: [
                    (activatedRouteSnapshot: ActivatedRouteSnapshot) => inject(HasRolesGuard).canActivate(activatedRouteSnapshot),
                ],
                data: {roles: [User.roles.GRP_LOCATION, User.roles.GRP_VENTE]},
                path: 'passerelles',
            },
            // Wildcard pour /app/configuration
            {path: '**', pathMatch: 'full', redirectTo: 'etude'},
        ],
    },
];

@NgModule({
    declarations: [
        AppLayoutConfigurationComponent,
        AppLayoutConfigurationDocumentsFooterComponent,
        AppLayoutConfigurationDocumentsHeaderComponent,
        AppLayoutConfigurationDossiersComponent,
        AppLayoutConfigurationEtudeComponent,
        AppLayoutConfigurationNetworkComponent,
    ],
    imports: [CommonModule, EskimmoModule, FeaturesModule, RouterModule.forChild(routes), SharedModule],
})
export class ConfigurationRoutingModule {
}
