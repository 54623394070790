<div appProvideParentForm>
  <div class="row">
    @if (showFieldForBien(FIELDS.bienType)) {
      <div class="col-xs-12 col-sm-3 col-md-4 col-lg-3">
        <div class="form-group margin-bottom-5">
          <label>
            Type
            @if (ngBienDossier._esk.typeDossier === DOSSIER_BIEN_TYPES.ESTIMATION) {
              d'estimation
            } @else if (ngBienDossier._esk.typeDossier === DOSSIER_BIEN_TYPES.VENTE) {
              de vente
            }
          </label>

          <div>
            <app-bien-edit-type-radio [typeBien]="ngBienDossier._esk.typeDossier!" [type]="bienDossierType"
                                      (selected)="onSelectBienDossierType($event)"/>
          </div>
        </div>
      </div>
    }

    <div class="col-xs-12 col-sm-9 col-md-8 col-lg-9">
      <div class="row">
        <div class="form-group col-xs-12 col-sm-6 col-md-4 col-lg-2"
             [appBienEditConstraints]="bienNatureForm.formInput">
          <label>Nature *</label>

          <app-dictionary-select-items #bienNatureForm="dictionarySelect" [options]="{name: 'nature', required: true}"
                                       [name]="DICTIONARY_NAMES_NATURES" (selected)="onSelectBienNature($event)"
                                       [model]="bienNature"/>
        </div>

        @if (showFieldForBien(FIELDS.designation)) {
          <div class="form-group col-xs-12 col-sm-6 col-md-4 col-lg-2">
            <label for="bien-designation">Désignation</label>

            <input type="text" id="bien-designation" class="form-control" name="designation"
                   [(ngModel)]="ngBienDossier.bien.option.designation">
          </div>
        }

        <div class="col-xs-12 col-sm-4 col-lg-2">
          <div class="form-group" [appFormError]="superficie">
            <label for="bien-superficie" class="tw-flex margin-bottom-5">
              {{ superficieLabel }}
              {{ ngBienDossier._esk.typeDossier === DOSSIER_BIEN_TYPES.ESTIMATION && !estimation.isEvaluation() ? '' : '*' }}

              <div class="tw-relative">
                @if (showFieldForBien(FIELDS.surfaces) && ngBienDossier.bien._esk.surfacePiecesHabitables! > 0) {
                  <button type="button" class="tw-absolute tw-bottom-0 tw-btn-tertiary-default tw-ml-1 tw-p-0"
                          (click)="setSuperficie()" title="Récupérer la surface totale des pièces habitables du bien">
                    <fa-icon [icon]="'sync-alt'"/>
                  </button>
                }
              </div>
            </label>

            <div class="input-group input-group-number input-group-area">
              <input type="text" id="bien-superficie" name="superficie" pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$"
                     #superficie="ngModel" [(ngModel)]="ngBienDossier.bien.superficie" class="form-control text-right"
                     [required]="ngBienDossier._esk.typeDossier !== DOSSIER_BIEN_TYPES.ESTIMATION && ngBienDossier.statut !== DOSSIER_BIEN_BROUILLON">

              <span class="input-group-addon">m<sup>2</sup></span>
            </div>
          </div>
        </div>

        @if (showFieldForBien(FIELDS.surfaceCarrez)) {
          <div class="col-xs-12 col-sm-4 col-lg-2 form-group" [appBienEditConstraints]="surfaceCarrez">
            <label for="bien-surface-carrez">
              Surface Carrez
              <a href="https://www.legifrance.gouv.fr/affichTexte.do?cidTexte=LEGITEXT000006061423&dateTexte=20080120"
                 target="_blank">
                <fa-icon [icon]="'external-link-alt'"/>
              </a>
            </label>

            <div class="input-group input-group-number input-group-area">
              <input type="text" id="bien-surface-carrez" class="form-control text-right" pattern="^(?:\d*.)?\d+$"
                     #surfaceCarrez="ngModel" [(ngModel)]="ngBienDossier.bien.option.surfaceCarrez"
                     name="surfaceCarrez">

              <span class="input-group-addon">m<sup>2</sup></span>
            </div>
          </div>
        }

        @if (ngBienDossier._esk.typeDossier === DOSSIER_BIEN_TYPES.ESTIMATION && showFieldForBien(FIELDS.surfaceCadastrale)) {
          <div class="col-xs-12 col-sm-4 col-lg-2 surface-cadastrale">
            <div class="form-group" [appBienEditConstraints]="surfaceCadastrale">
              <label for="bien-surface-cadastrale">
                Surface cadastrale
                <button type="button" title="Calculer la surface cadastrale grâce aux références cadastrales du bien"
                        class="btn btn-default btn-null surface-cadastrale-operate"
                        (click)="ngBienDossier.bien.operateSurfaceCadastraleFromRefs(true)">
                  <fa-icon [icon]="'sync-alt'"/>
                </button>
              </label>

              <div class="input-group input-group-number input-group-area">
                <input type="text" id="bien-surface-cadastrale" class="form-control text-right"
                       pattern="^(?:\d*.)?\d+$" [(ngModel)]="ngBienDossier.bien.option.surfaceCadastrale"
                       name="surfaceCadastrale" #surfaceCadastrale="ngModel">

                <span class="input-group-addon">m<sup>2</sup></span>
              </div>
            </div>
          </div>
        }

        @if (showFieldForBien(FIELDS.meuble)) {
          <div class="form-group col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <label>Meublé</label>

            <app-form-radio-ynn [model]="ngLocation.meuble" (selected)="ngLocation.meuble = $event"/>
          </div>
        }
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-7 col-md-8 col-lg-9">
      <div class="form-group" [appFormError]="titre">
        <label for="bien-titre">
          Titre{{ ngBienDossier._esk.typeDossier === DOSSIER_BIEN_TYPES.ESTIMATION ? '' : ' *' }}
        </label>

        <input type="text" id="bien-titre" name="titre" class="form-control" [(ngModel)]="ngBienDossier.bien.titre"
               #titre="ngModel" maxlength="100"
               [required]="ngBienDossier._esk.typeDossier === DOSSIER_BIEN_TYPES.ESTIMATION || ngBienDossier.statut === DOSSIER_BIEN_BROUILLON ? null! : 'true'">

        @if (!!ngBienDossier.bien.titre) {
          <div class="info-count pull-right">
            {{ ngBienDossier.bien.titre.length }} {{ ngBienDossier.bien.titre.length > 1 ? 'caractères' : 'caractère' }}
          </div>
        }
      </div>
    </div>

    <div class="form-group col-xs-12 col-sm-5 col-md-4 col-lg-3 auto-generation">
      @if (!!ngBienDossier.uuid) {
        <div>
          <label>Génération du titre et descriptif</label>

          <soq-popover-template [display]="'default'" [dossier]="ngBienDossier" [placement]="'left'"
                                [template]="'src/app/legacy/templates/biens/components/item/edit/description/auto-generation.help.html'"
                                [trigger]="'outsideClick'"/>

          <div class="action">
            @if (loaderTitreDescriptif) {
              <p><fa-icon [icon]="'spinner'" animation="spin"/> Chargement des données ...</p>
            } @else {
              <button type="button" class="btn btn-link" (click)="setTitreDescriptif()">
                {{ !ngBienDossier.bien.titre || !ngBienDossier.bien.descriptif ?
                    'Pré-saisir le titre et le descriptif' : 'Remplacer le titre et le descriptif' }}
              </button>
            }
          </div>
        </div>
      }
    </div>

    <div class="col-xs-12 descriptifs">
      <div class="tw-my-2">
        @if (descriptifTabs.length > 1) {
          <ul class="tw-flex tw-gap-2">
            @for (tab of descriptifTabs; track tab) {
              <li>
                <button [ngClass]="{'tw-btn-primary-info': tab.index === descriptifTab.index, 'tw-btn-tertiary-info': tab.index !== descriptifTab.index}"
                        type="button" (click)="descriptifTab = tab" class="tw-text-xs">{{ tab.heading }}</button>
              </li>
            }
          </ul>
        } @else {
          {{ descriptifTabs[0].heading }}
        }
      </div>

      @if (descriptifTab.index === 'GENERAL') {
        <div [appFormError]="descriptif">
          <textarea class="form-control" name="descriptif" #descriptif="ngModel"
                    placeholder="Saisir le descriptif général du bien (ne pas saisir d'informations nominatives, téléphoniques ou email)"
                    [(ngModel)]="ngBienDossier.bien.descriptif"
                    [required]="ngBienDossier.statut !== DOSSIER_BIEN_BROUILLON" [minHeight]="100"></textarea>

          @if (currentEtude$ | async; as currentEtude) {
            @if (ngBienDossier._esk.typeDossier !== DOSSIER_BIEN_TYPES.ESTIMATION && currentEtude.hasNetwork()) {
              <div>Ne pas renseigner vos coordonnées dans le descriptif général</div>
            }
          }
        </div>
      }

      @if (descriptifTab.index === 'MANDAT') {
        <textarea class="form-control" name="descriptifMandat" [(ngModel)]="ngBienDossier.bien.descriptifMandat"
                  placeholder="Saisir le descriptif pour le mandat" [minHeight]="100"></textarea>
      }

      @if (descriptifTab.index === 'AFFICHETTE') {
        <textarea class="form-control" name="descriptifAffichette" [(ngModel)]="ngBienDossier.bien.descriptifAffichette"
                  placeholder="Saisir le descriptif pour les affichettes" [minHeight]="100"></textarea>
      }

      @if (descriptifTab.index === 'SITE_INTERNET') {
        <textarea class="form-control" name="descriptifSiteInternet" [minHeight]="100"
                  [(ngModel)]="ngBienDossier.bien.descriptifSiteInternet"
                  placeholder="Saisir le descriptif envoyé aux sites Internet"></textarea>
      }

      @if (descriptifTab.index === 'JOURNAUX_PAPIER') {
        <textarea [(ngModel)]="ngBienDossier.bien.descriptifJournalPapier" [minHeight]="100" class="form-control"
                  name="descriptifJournalPapier" placeholder="Saisir le descriptif envoyé aux journaux papier">
        </textarea>

        @if (!!ngBienDossier.bien.descriptifJournalPapier) {
          <div class="info-count pull-right">
            {{ ngBienDossier.bien.descriptifJournalPapier.length }}
            {{ ngBienDossier.bien.descriptifJournalPapier.length > 1 ? 'caractères' : 'caractère' }}
          </div>
        }
      }

      @if (descriptifTab.index !== 'GENERAL') {
        <button type="button" class="btn btn-link" (click)="ngBienDossier.bien.initDescriptif(descriptifTab.index)">
          Reprendre le descriptif général du bien
        </button>
      }
    </div>
  </div>
</div>
