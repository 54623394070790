import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';

@Injectable({providedIn: 'root'})
export class VenteContratsApiService {
    private _modelApiService = inject(ModelApiService);

    cancel$(venteUuid: string, uuid: string): Observable<void> {
        return this._modelApiService.patch$(`/ventes/${venteUuid}/contrats/${uuid}/cancel`);
    }

    delete$(venteUuid: string, uuid: string): Observable<void> {
        return this._modelApiService.delete$(`/ventes/${venteUuid}/contrats/${uuid}`);
    }
}
