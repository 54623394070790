import {inject, Injectable} from '@angular/core';
import VenteContrat from '@models/ventes/vente/contrats/contrat/vente-contrat.model';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {CProceduresService} from '@models/procedures/collection/procedures.collection.service';

@Injectable({providedIn: 'root'})
export class VenteContratService {
    private _cProceduresService = inject(CProceduresService);

    isCancelable$(venteContrat: VenteContrat): Observable<boolean> {
        if (venteContrat.isMandat() && !venteContrat.isCanceled()) {
            return this._cProceduresService.getFirst$(venteContrat.linkContratProcedures).pipe(map(procedure => {
                if (!procedure) {
                    return true;
                }

                return procedure.isCanceled() || procedure.isExpired() || procedure.isNewOrDraft();
            }));
        }

        return of(false);
    }
}
