import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject, switchMap} from 'rxjs';
import {map, take, takeUntil, tap} from 'rxjs/operators';
import {CEtudesFactory} from '@models/etudes/collection/etudes.collection.factory';
import Etude from '@models/etudes/etude/etude.model';
import CEtudes from '@models/etudes/collection/etudes.collection.model';
import {ICEtudesQueryParameters} from '@models/etudes/collection/etudes.collection.interfaces';

@Injectable({providedIn: 'root'})
export class CEtudesService {
    private _cEtudesFactory = inject(CEtudesFactory);
    private _currentSource = new BehaviorSubject<CEtudes>(undefined!);
    private readonly _endCurrentSubject = new Subject<void>();

    get current$(): Observable<CEtudes> {
        return this._currentSource.asObservable();
    }

    addNextToCurrent$(): Observable<CEtudes> {
        return this.current$.pipe(
            take(1),
            switchMap(cEtudes => this._cEtudesFactory.getNext$(cEtudes).pipe(
                tap(newCEtudes => cEtudes.updateNext(newCEtudes)),
                map(_ => cEtudes),
            )),
        );
    }

    initCurrent(queryParameters: ICEtudesQueryParameters): void {
        this._endCurrentSubject.next();
        this._currentSource.next(undefined!);
        this._cEtudesFactory.get$(queryParameters).pipe(
            takeUntil(this._endCurrentSubject),
        ).subscribe(currentCEtudes => this._currentSource.next(currentCEtudes));
    }

    getAll$(ignoredEtude: Etude): Observable<CEtudes> {
        return this._cEtudesFactory.get$().pipe(
            switchMap(cEtudes => this.updateAll$(cEtudes)),
            tap(cEtudes => cEtudes.removeResult(etude => etude.id === ignoredEtude.id)),
        );
    }

    // @todo Trouver un nom plus approprié (celui-ci donne l'impression d'envoyer les modifications à l'API et modifier pour les autres collections
    updateAll$(cEtudes: CEtudes): Observable<CEtudes> {
        if (cEtudes.links.next) {
            return this._cEtudesFactory.getNext$(cEtudes).pipe(
                tap(newCEtudes => cEtudes.updateNext(newCEtudes)),
                switchMap(_ => this.updateAll$(cEtudes)),
            );
        }

        return of(cEtudes);
    }
}
