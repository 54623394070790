import AModel from '@models/model.abstract';

export default class VenteContrat extends AModel {
    static readonly CONTRAT_STATUTS = {CANCELED: 'canceled', STARTED: 'started'};
    static readonly TYPES = {AVENANT: 'avenant', MANDAT: 'mandat'};
    private _contratStatut!: string;
    private _linkContratProcedures!: string;
    private _type!: string;

    get contratStatut(): string {
        return this._contratStatut;
    }

    set contratStatut(value: string) {
        this._contratStatut = value;
    }

    get linkContratProcedures(): string {
        return this._linkContratProcedures;
    }

    set linkContratProcedures(value: string) {
        this._linkContratProcedures = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    isCanceled(): boolean {
        return this.contratStatut === VenteContrat.CONTRAT_STATUTS.CANCELED;
    }

    isMandat(): boolean {
        return this.type === VenteContrat.TYPES.MANDAT;
    }
}
