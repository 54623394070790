import {NgModule} from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {CommonModule} from '@angular/common';
import {
    DCNotestimBiensSelectComponent
} from '@features/notestim/biens/select/dynamic-components/notestim-biens-select.component';
import {
    DCNotestimBienImportComponent
} from '@features/notestim/biens/bien/import/dynamic-components/notestim-bien-import.component';
import {DCNotestimPublicitesComponent} from '@features/notestim/publicites/notestim-publicites.component';

@NgModule({
    declarations: [DCNotestimBienImportComponent, DCNotestimBiensSelectComponent, DCNotestimPublicitesComponent],
    imports: [CommonModule, SharedModule],
})
export class NotestimModule {
}
