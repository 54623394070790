@if (rapportMedia$ | async; as rapportMedia) {
  @if (rapportMedia.linkDownload) {
    @if (estimationNotestim$ | async; as estimationNotestim) {
      @if (estimation$ | async; as estimation) {
        @if (estimation.valeurVenale !== estimationNotestim.scanDataResalePrice) {
          <div class="tw-flex tw-justify-center tw-items-center tw-gap-4 tw-w-full tw-py-8 tw-text-center">
            <p class="tw-m-0 tw-text-base tw-text-gray-500">
              <!-- 16px et mb -3px pour 20px -->
              La valeur vénale saisie dans <app-noteo-logo-noty class="tw-h-[16px] tw-mb-[-3px]"/> est différente de
              <!-- 14px pour 20px -->
              celle calculée par <app-noteo-logo-notestim class="tw-h-[14px]"/>.
            </p>

            <button type="button" class="tw-btn-secondary-info" (click)="updateFromNotestim()">Mettre à jour</button>
          </div>
        } @else {
          <div class="tw-mb-2 tw-text-center">
            <button class="tw-btn-tertiary-info tw-items-baseline tw-p-0 tw-text-xs tw-font-normal"
                    type="button" (click)="updateFromNotestim()">
              <!-- 11px pour 16px -->
              Mettre à jour les données depuis <app-noteo-logo-notestim class="tw-h-[11px]"/>
            </button>
          </div>
        }
      }

      <div class="tw-h-[50rem] tw-w-full lg:tw-w-[calc(21cm_+_8rem)] tw-mx-auto">
        <app-pdf [pdfPath]="rapportMedia.linkDownload"/>
      </div>

      <div class="tw-my-4 tw-text-center">
        <!-- 11px pour 16px -->
        Rapport généré sur <app-noteo-logo-notestim class="tw-h-[11px]"/> le
        {{ estimationNotestim.scanGeneratedAt | date:'longDate' }} à
        {{ estimationNotestim.scanGeneratedAt | date: 'shortTime' }}
      </div>
    }
  } @else {
    <div class="tw-flex tw-flex-col tw-items-center tw-gap-8 tw-p-16">
      <app-noteo-logo-notestim class="tw-max-w-[400px] tw-w-full"/>

      <h2 class="tw-text-septeo-blue-900 tw-font-normal">
        <!-- 23px pour 33px -->
        La création du rapport avec <app-noteo-logo-notestim class="tw-h-[23px]"/> n'est pas arrivée à son terme.
      </h2>
    </div>
  }

  <div class="tw-w-full tw-py-8 tw-text-center">
    <p class="tw-text-base tw-text-gray-500">
      Pour modifier votre rapport, retournez dans la partie
      <a [routerLink]="['../../evaluation']">"Évaluation" de votre bien en estimation</a> et modifier le avec
      <!-- 14px pour 20px -->
      <app-noteo-logo-notestim class="tw-h-[14px]"/>.
    </p>
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
}
