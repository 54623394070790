import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable, of, switchMap} from 'rxjs';
import {tap} from 'rxjs/operators';
import {
    CDossierFileContactsFactory
} from '@models/dossiers/dossier/files/file/contacts/collection/dossier-file-contacts.collection.factory';
import CDossierFileContacts
    from '@models/dossiers/dossier/files/file/contacts/collection/dossier-file-contacts.collection.model';
import Contact from '@models/contacts/contact/contact.model';
import {ContactFactory} from '@models/contacts/contact/contact.factory';
import {
    ICDossierFileContactsQueryParameters
} from '@models/dossiers/dossier/files/file/contacts/collection/dossier-file-contacts.collection.interfaces';

@Injectable({providedIn: 'root'})
export class CDossierFileContactsService {
    private _contactFactory = inject(ContactFactory);
    private _cDossierFileContactsFactory = inject(CDossierFileContactsFactory);

    getAllByLink$(link: string, queryParameters?: ICDossierFileContactsQueryParameters): Observable<CDossierFileContacts> {
        return this._cDossierFileContactsFactory.getByLink$(link, queryParameters).pipe(
            switchMap(cDossierFileContacts => this.updateAll$(cDossierFileContacts)),
        );
    }

    getContactsByLink$(link: string, queryParameters?: ICDossierFileContactsQueryParameters): Observable<Contact[]> {
        return this.getAllByLink$(link, queryParameters).pipe(
            switchMap(cDossierFileContacts => {
                if (cDossierFileContacts.total <= 0) {
                    return of([]);
                }

                return combineLatest(cDossierFileContacts.results.map(dossierFileContact => this._contactFactory.getByLink$(dossierFileContact.linkContact)));
            }),
        );
    }

    // @todo Trouver un nom plus approprié (celui-ci donne l'impression d'envoyer les modifications à l'API et modifier pour les autres collections
    updateAll$(cDossierFileContacts: CDossierFileContacts): Observable<CDossierFileContacts> {
        if (cDossierFileContacts.links.next) {
            return this._cDossierFileContactsFactory.getNext$(cDossierFileContacts).pipe(
                tap(newCEtudes => cDossierFileContacts.updateNext(newCEtudes)),
                switchMap(_ => this.updateAll$(cDossierFileContacts)),
            );
        }

        return of(cDossierFileContacts);
    }
}
