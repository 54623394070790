import ContactsGroup from '@models/contacts-group/contacts-group.model';
import Commune from '@models/communes/commune/commune.model';
import Quartier from '@models/quartiers/quartier/quartier.model';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import BienDiagnostic from '@models/bien/diagnostics/diagnostic/bien-diagnostic.model';
import BienSurface from '@models/bien/surfaces/surface/bien-surface.model';
import BienLogement from '@models/bien/logements/logement/bien-logement.model';
import BienCommodite from '@models/bien/commodites/commodite/bien-commodite.model';
import BienCharge from '@models/bien/charges/charge/bien-charge.model';
import BienPerformanceEnergetique from '@models/bien/performance-energetique/bien-performance-energetique.model';
import CommuneVoie from '@models/communes/commune/voies/voie/commune-voie.model';
import BienCadastre from '@models/bien/cadastres/cadastre/bien-cadastre.model';
import {UrlService} from '@shared/texts/url/url.service';
import {ImageSizeConst} from '@shared/constants';
import {DossierBienFieldsConst} from '@models/dossiers/biens/dossier-biens.constants';

export default class Bien {
    static readonly descriptifs = {DEFAULT: 'default', MANDAT: 'mandat'};
    static readonly natures = {
        APPARTEMENT: 'APPARTEMENT',
        AUTRE: 'AUTRE',
        AUTRE_TERRAIN: 'AUTRE_TERRAIN',
        BUREAUX: 'BUREAUX',
        GARAGE: 'GARAGE',
        IMMEUBLE: 'IMMEUBLE',
        LOCAUX_COMMERCIAUX: 'LOCAUX_COMMERCIAUX',
        MAISON: 'MAISON',
        TERRAIN_A_BATIR: 'TERRAIN_A_BATIR',
    };
    static readonly viabilisationAssainissements = {AUTONOME: 'autonome', COLLECTIF: 'collectif'};
    private _accesHandicape!: boolean;
    private _administrationSyndicLoiAlur!: boolean;
    private _adresseReseau!: string;
    private _alarmeLocation!: boolean;
    private _alarmePleinePropriete!: boolean;
    private _ancienneReference!: string;
    private _anneeConstruction!: number;
    private _annexe!: boolean;
    private _ascenseur!: boolean;
    private _assainissementNonConforme!: boolean;
    private _assainissements: DictionaryItem[] = [];
    private _aucunAssainissement!: boolean;
    private _aucunChauffage!: boolean;
    private _balcon!: boolean;
    private _cadastres: BienCadastre[] = [];
    private _causeVente!: string;
    private _cave!: boolean;
    private _chambreRdc!: boolean;
    private _charges: BienCharge[] = [];
    private _chargesLocatives!: number;
    private _charpentes: DictionaryItem[] = [];
    private _chauffageCollectif!: boolean;
    private _chauffages: DictionaryItem[] = [];
    private _cheminee!: boolean;
    private _chemineeFonctionnelle!: boolean;
    private _chemineeOrnement!: boolean;
    private _citerneGazLocation!: boolean;
    private _citerneGazPleinePropriete!: boolean;
    private _clesVisite!: string;
    private _clotures: DictionaryItem[] = [];
    private _codePostal!: string;
    private _combles!: boolean;
    private _commentsAccessibiliteEtEnvironnement!: string;
    private _commentsAgencementExterieur!: string;
    private _commentsAgencementInterieur!: string;
    private _commentsBatiment!: string;
    private _commentsCharges!: string;
    private _commentsConfidentiel!: string;
    private _commentsConfreres!: string;
    private _commentsConstruction!: string;
    private _commentsCouts!: string;
    private _commentsLoiAlur!: string;
    private _commentsRisques!: string;
    private _commentsTerrain!: string;
    private _commentsUrbanisme!: string;
    private _commentsVue!: string;
    private _commodites: BienCommodite[] = [];
    private _commune!: Commune;
    private _complementAdresse1!: string;
    private _connexionsInternet: DictionaryItem[] = [];
    private _contactsGroup!: ContactsGroup;
    private _coordonneesSyndicLoiAlur!: string;
    private _cour!: boolean;
    private _cuisineEquipee!: boolean;
    private _cuveFuelAerienne!: boolean;
    private _cuveFuelEnterree!: boolean;
    private _cuveInactiveDegazee!: boolean;
    private _cuveInactiveNeutralizee!: boolean;
    private _dateArchive!: string;
    private _dateDisponibilite!: string;
    private _declivite!: boolean;
    private _degreDeclivite!: number;
    private _dernierEtage!: boolean;
    private _descriptif!: string;
    private _descriptifAffichette!: string;
    private _descriptifJournalPapier!: string;
    private _descriptifMandat!: string;
    private _descriptifSiteInternet!: string;
    private _designation!: string;
    private _detecteurFumee!: boolean;
    private _diagnosticsRealises: BienDiagnostic[] = [];
    private _epoqueConstruction!: DictionaryItem;
    private _etat!: DictionaryItem;
    private _exceptionnel!: boolean;
    private _exposition!: DictionaryItem;
    private _fullInlineAdresse = '';
    private _garage!: boolean;
    private _grenier!: boolean;
    private _hasLoiAlur!: boolean;
    private _hauteurSousPlafond!: number;
    private _huisseries: DictionaryItem[] = [];
    private _inlineAdresse!: string;
    private _isolations: DictionaryItem[] = [];
    private _linkNotaire!: string;
    private _linkPhotos!: string;
    private _linkPremierePhoto!: string;
    private _linkPremierePhoto16by9!: string;
    private _linkPremierePhotoOptimized!: string;
    private _linkPremierePhotoThumbnail!: string;
    private _linkPrivatePhotos!: string;
    private _linkPrivatePremierePhoto!: string;
    private _linkProtectedPhotos!: string;
    private _linkProtectedPremierePhoto!: string;
    private _linkResponsableDossier!: string;
    private _logements: BienLogement[] = [];
    private _longueurFacade!: number;
    private _loyerActuel!: number;
    private _materiauxConstruction: DictionaryItem[] = [];
    private _materiauxCouverture: DictionaryItem[] = [];
    private _mitoyennete!: DictionaryItem;
    private _montantQuotePartLoiAlur!: number;
    private _montantTravauxLoiAlur!: number;
    private _nature!: DictionaryItem;
    private _nombreBains!: number;
    private _nombreChambres!: number;
    private _nombreCheminees!: number;
    private _nombreCoproprietairesLoiAlur!: number;
    private _nombreDouches!: number;
    private _nombreEtages!: number;
    private _nombreEtagesBatiment!: number;
    private _nombreLogements!: number;
    private _nombreLotsLoiAlur!: number;
    private _nombrePieces!: number;
    private _nombrePlacesParking!: number;
    private _nombreSallesBains!: number;
    private _nombreSallesEau!: number;
    private _nombreWc!: number;
    private _nomVoie!: string;
    private _notaireId!: number;
    private _numeroCle!: string;
    private _numeroEtage!: number;
    private _numeroVoie!: number;
    private _occupation!: DictionaryItem;
    private _panneaux!: string;
    private _panneauxPhotovoltaiques!: boolean;
    private _performanceEnergetique = {} as BienPerformanceEnergetique;
    private _pieceEauRdc!: boolean;
    private _piscine!: boolean;
    private _plainPied!: DictionaryItem;
    private _planSauvegardeLoiAlur!: boolean;
    private _pointEauCampagne!: boolean;
    private _pointEauVille!: boolean;
    private _quartier!: Quartier;
    private _raccordements: DictionaryItem[] = [];
    private _raccordementsMap = new Map<string, DictionaryItem>();
    private _referenceInterne!: string;
    private _residence!: DictionaryItem;
    private _residenceService!: boolean;
    private _responsableDossierId!: number;
    private _servitude!: string;
    private _sousSol!: boolean;
    private _superficie!: number;
    private _surfaceAnnexe!: number;
    private _surfaceBalcon!: number;
    private _surfaceBatie!: number;
    private _surfaceCadastrale!: number;
    private _surfaceCarrez!: number;
    private _surfaceCave!: number;
    private _surfaceCour!: number;
    private _surfaceGarage!: number;
    private _surfaceNonBatie!: number;
    private _surfacePlancher!: number;
    private _surfaces: BienSurface[] = [];
    private _surfaceSejour!: number;
    private _surfaceTerrasse!: number;
    private _terrasse!: boolean;
    private _titre!: string;
    private _urlVisiteVirtuelle!: string;
    private _usage!: DictionaryItem;
    private _veranda!: boolean;
    private _viabilisable!: boolean;
    private _viabilisationAssainissement!: string;
    private _viabilisationAssainissementCollectifRaccorde!: boolean;
    private _viabilisationComments!: string;
    private _viabilisationEau!: boolean;
    private _viabilisationElectricite!: boolean;
    private _viabilisationFibre!: boolean;
    private _viabilisationGaz!: boolean;
    private _viabilisationTelecom!: boolean;
    private _viabilisationVoirie!: boolean;
    private _voie!: CommuneVoie;
    private _volets: DictionaryItem[] = [];
    private _vues: DictionaryItem[] = [];
    private _wcBroyeur!: boolean;
    private _zoneInondable!: boolean;
    private _zonePLU!: string;
    private _zoneRisques!: boolean;

    get accesHandicape(): boolean {
        return this._accesHandicape;
    }

    set accesHandicape(value: boolean) {
        this._accesHandicape = value;
    }

    get administrationSyndicLoiAlur(): boolean {
        return this._administrationSyndicLoiAlur;
    }

    set administrationSyndicLoiAlur(value: boolean) {
        this._administrationSyndicLoiAlur = value;
    }

    get adresseReseau(): string {
        return this._adresseReseau;
    }

    set adresseReseau(value: string) {
        this._adresseReseau = value;
        this.setInlineAdresses();
    }

    get alarmeLocation(): boolean {
        return this._alarmeLocation;
    }

    set alarmeLocation(value: boolean) {
        this._alarmeLocation = value;
    }

    get alarmePleinePropriete(): boolean {
        return this._alarmePleinePropriete;
    }

    set alarmePleinePropriete(value: boolean) {
        this._alarmePleinePropriete = value;
    }

    get ancienneReference(): string {
        return this._ancienneReference;
    }

    set ancienneReference(value: string) {
        this._ancienneReference = value;
    }

    get anneeConstruction(): number {
        return this._anneeConstruction;
    }

    set anneeConstruction(value: number) {
        this._anneeConstruction = value;
    }

    get annexe(): boolean {
        return this._annexe;
    }

    set annexe(value: boolean) {
        this._annexe = value;
    }

    get ascenseur(): boolean {
        return this._ascenseur;
    }

    set ascenseur(value: boolean) {
        this._ascenseur = value;
    }

    get assainissementNonConforme(): boolean {
        return this._assainissementNonConforme;
    }

    set assainissementNonConforme(value: boolean) {
        this._assainissementNonConforme = value;
    }

    get assainissements(): DictionaryItem[] {
        return this._assainissements;
    }

    set assainissements(value: DictionaryItem[]) {
        this._assainissements = value;
    }

    get aucunAssainissement(): boolean {
        return this._aucunAssainissement;
    }

    set aucunAssainissement(value: boolean) {
        this._aucunAssainissement = value;
    }

    get aucunChauffage(): boolean {
        return this._aucunChauffage;
    }

    set aucunChauffage(value: boolean) {
        this._aucunChauffage = value;
    }

    get balcon(): boolean {
        return this._balcon;
    }

    set balcon(value: boolean) {
        this._balcon = value;
    }

    get cadastres(): BienCadastre[] {
        return this._cadastres;
    }

    set cadastres(value: BienCadastre[]) {
        this._cadastres = value;
    }

    get causeVente(): string {
        return this._causeVente;
    }

    set causeVente(value: string) {
        this._causeVente = value;
    }

    get cave(): boolean {
        return this._cave;
    }

    set cave(value: boolean) {
        this._cave = value;
    }

    get chambreRdc(): boolean {
        return this._chambreRdc;
    }

    set chambreRdc(value: boolean) {
        this._chambreRdc = value;
    }

    get charges(): BienCharge[] {
        return this._charges;
    }

    set charges(value: BienCharge[]) {
        this._charges = value;
    }

    get chargesLocatives(): number {
        return this._chargesLocatives;
    }

    set chargesLocatives(value: number) {
        this._chargesLocatives = value;
    }

    get charpentes(): DictionaryItem[] {
        return this._charpentes;
    }

    set charpentes(value: DictionaryItem[]) {
        this._charpentes = value;
    }

    get chauffageCollectif(): boolean {
        return this._chauffageCollectif;
    }

    set chauffageCollectif(value: boolean) {
        this._chauffageCollectif = value;
    }

    get chauffages(): DictionaryItem[] {
        return this._chauffages;
    }

    set chauffages(value: DictionaryItem[]) {
        this._chauffages = value;
    }

    get cheminee(): boolean {
        return this._cheminee;
    }

    set cheminee(value: boolean) {
        this._cheminee = value;
    }

    get chemineeFonctionnelle(): boolean {
        return this._chemineeFonctionnelle;
    }

    set chemineeFonctionnelle(value: boolean) {
        this._chemineeFonctionnelle = value;
    }

    get chemineeOrnement(): boolean {
        return this._chemineeOrnement;
    }

    set chemineeOrnement(value: boolean) {
        this._chemineeOrnement = value;
    }

    get citerneGazLocation(): boolean {
        return this._citerneGazLocation;
    }

    set citerneGazLocation(value: boolean) {
        this._citerneGazLocation = value;
    }

    get citerneGazPleinePropriete(): boolean {
        return this._citerneGazPleinePropriete;
    }

    set citerneGazPleinePropriete(value: boolean) {
        this._citerneGazPleinePropriete = value;
    }

    get clesVisite(): string {
        return this._clesVisite;
    }

    set clesVisite(value: string) {
        this._clesVisite = value;
    }

    get clotures(): DictionaryItem[] {
        return this._clotures;
    }

    set clotures(value: DictionaryItem[]) {
        this._clotures = value;
    }

    get codePostal(): string {
        return this._codePostal;
    }

    set codePostal(value: string) {
        this._codePostal = value;
        this.setInlineAdresses();
    }

    get combles(): boolean {
        return this._combles;
    }

    set combles(value: boolean) {
        this._combles = value;
    }

    get commentsAccessibiliteEtEnvironnement(): string {
        return this._commentsAccessibiliteEtEnvironnement;
    }

    set commentsAccessibiliteEtEnvironnement(value: string) {
        this._commentsAccessibiliteEtEnvironnement = value;
    }

    get commentsAgencementExterieur(): string {
        return this._commentsAgencementExterieur;
    }

    set commentsAgencementExterieur(value: string) {
        this._commentsAgencementExterieur = value;
    }

    get commentsAgencementInterieur(): string {
        return this._commentsAgencementInterieur;
    }

    set commentsAgencementInterieur(value: string) {
        this._commentsAgencementInterieur = value;
    }

    get commentsBatiment(): string {
        return this._commentsBatiment;
    }

    set commentsBatiment(value: string) {
        this._commentsBatiment = value;
    }

    get commentsCharges(): string {
        return this._commentsCharges;
    }

    set commentsCharges(value: string) {
        this._commentsCharges = value;
    }

    get commentsConfidentiel(): string {
        return this._commentsConfidentiel;
    }

    set commentsConfidentiel(value: string) {
        this._commentsConfidentiel = value;
    }

    get commentsConfreres(): string {
        return this._commentsConfreres;
    }

    set commentsConfreres(value: string) {
        this._commentsConfreres = value;
    }

    get commentsConstruction(): string {
        return this._commentsConstruction;
    }

    set commentsConstruction(value: string) {
        this._commentsConstruction = value;
    }

    get commentsCouts(): string {
        return this._commentsCouts;
    }

    set commentsCouts(value: string) {
        this._commentsCouts = value;
    }

    get commentsLoiAlur(): string {
        return this._commentsLoiAlur;
    }

    set commentsLoiAlur(value: string) {
        this._commentsLoiAlur = value;
    }

    get commentsRisques(): string {
        return this._commentsRisques;
    }

    set commentsRisques(value: string) {
        this._commentsRisques = value;
    }

    get commentsTerrain(): string {
        return this._commentsTerrain;
    }

    set commentsTerrain(value: string) {
        this._commentsTerrain = value;
    }

    get commentsUrbanisme(): string {
        return this._commentsUrbanisme;
    }

    set commentsUrbanisme(value: string) {
        this._commentsUrbanisme = value;
    }

    get commentsVue(): string {
        return this._commentsVue;
    }

    set commentsVue(value: string) {
        this._commentsVue = value;
    }

    get commodites(): BienCommodite[] {
        return this._commodites;
    }

    set commodites(value: BienCommodite[]) {
        this._commodites = value;
    }

    get commune(): Commune {
        return this._commune;
    }

    set commune(value: Commune) {
        this._commune = value;
        this.setInlineAdresses();
    }

    get complementAdresse1(): string {
        return this._complementAdresse1;
    }

    set complementAdresse1(value: string) {
        this._complementAdresse1 = value;
    }

    get connexionsInternet(): DictionaryItem[] {
        return this._connexionsInternet;
    }

    set connexionsInternet(value: DictionaryItem[]) {
        this._connexionsInternet = value;
    }

    get contactsGroup(): ContactsGroup {
        return this._contactsGroup;
    }

    set contactsGroup(value: ContactsGroup) {
        this._contactsGroup = value;
    }

    get coordonneesSyndicLoiAlur(): string {
        return this._coordonneesSyndicLoiAlur;
    }

    set coordonneesSyndicLoiAlur(value: string) {
        this._coordonneesSyndicLoiAlur = value;
    }

    get cour(): boolean {
        return this._cour;
    }

    set cour(value: boolean) {
        this._cour = value;
    }

    get cuisineEquipee(): boolean {
        return this._cuisineEquipee;
    }

    set cuisineEquipee(value: boolean) {
        this._cuisineEquipee = value;
    }

    get cuveFuelAerienne(): boolean {
        return this._cuveFuelAerienne;
    }

    set cuveFuelAerienne(value: boolean) {
        this._cuveFuelAerienne = value;
    }

    get cuveFuelEnterree(): boolean {
        return this._cuveFuelEnterree;
    }

    set cuveFuelEnterree(value: boolean) {
        this._cuveFuelEnterree = value;
    }

    get cuveInactiveDegazee(): boolean {
        return this._cuveInactiveDegazee;
    }

    set cuveInactiveDegazee(value: boolean) {
        this._cuveInactiveDegazee = value;
    }

    get cuveInactiveNeutralizee(): boolean {
        return this._cuveInactiveNeutralizee;
    }

    set cuveInactiveNeutralizee(value: boolean) {
        this._cuveInactiveNeutralizee = value;
    }

    get dateArchive(): string {
        return this._dateArchive;
    }

    set dateArchive(value: string) {
        this._dateArchive = value;
    }

    get dateDisponibilite(): string {
        return this._dateDisponibilite;
    }

    set dateDisponibilite(value: string) {
        this._dateDisponibilite = value;
    }

    get declivite(): boolean {
        return this._declivite;
    }

    set declivite(value: boolean) {
        this._declivite = value;
    }

    get degreDeclivite(): number {
        return this._degreDeclivite;
    }

    set degreDeclivite(value: number) {
        this._degreDeclivite = value;
    }

    get dernierEtage(): boolean {
        return this._dernierEtage;
    }

    set dernierEtage(value: boolean) {
        this._dernierEtage = value;
    }

    get descriptif(): string {
        return this._descriptif;
    }

    set descriptif(value: string) {
        this._descriptif = value;
    }

    get descriptifAffichette(): string {
        return this._descriptifAffichette;
    }

    set descriptifAffichette(value: string) {
        this._descriptifAffichette = value;
    }

    get descriptifJournalPapier(): string {
        return this._descriptifJournalPapier;
    }

    set descriptifJournalPapier(value: string) {
        this._descriptifJournalPapier = value;
    }

    get descriptifMandat(): string {
        return this._descriptifMandat;
    }

    set descriptifMandat(value: string) {
        this._descriptifMandat = value;
    }

    get descriptifSiteInternet(): string {
        return this._descriptifSiteInternet;
    }

    set descriptifSiteInternet(value: string) {
        this._descriptifSiteInternet = value;
    }

    get designation(): string {
        return this._designation;
    }

    set designation(value: string) {
        this._designation = value;
    }

    get detecteurFumee(): boolean {
        return this._detecteurFumee;
    }

    set detecteurFumee(value: boolean) {
        this._detecteurFumee = value;
    }

    get diagnosticsRealises(): BienDiagnostic[] {
        return this._diagnosticsRealises;
    }

    set diagnosticsRealises(value: BienDiagnostic[]) {
        this._diagnosticsRealises = value;
    }

    get epoqueConstruction(): DictionaryItem {
        return this._epoqueConstruction;
    }

    set epoqueConstruction(value: DictionaryItem) {
        this._epoqueConstruction = value;
    }

    get etat(): DictionaryItem {
        return this._etat;
    }

    set etat(value: DictionaryItem) {
        this._etat = value;
    }

    get exceptionnel(): boolean {
        return this._exceptionnel;
    }

    set exceptionnel(value: boolean) {
        this._exceptionnel = value;
    }

    get exposition(): DictionaryItem {
        return this._exposition;
    }

    set exposition(value: DictionaryItem) {
        this._exposition = value;
    }

    get fullInlineAdresse(): string {
        return this._fullInlineAdresse;
    }

    get garage(): boolean {
        return this._garage;
    }

    set garage(value: boolean) {
        this._garage = value;
    }

    get grenier(): boolean {
        return this._grenier;
    }

    set grenier(value: boolean) {
        this._grenier = value;
    }

    get hasLoiAlur(): boolean {
        return this._hasLoiAlur;
    }

    set hasLoiAlur(value: boolean) {
        this._hasLoiAlur = value;
    }

    get hauteurSousPlafond(): number {
        return this._hauteurSousPlafond;
    }

    set hauteurSousPlafond(value: number) {
        this._hauteurSousPlafond = value;
    }

    get huisseries(): DictionaryItem[] {
        return this._huisseries;
    }

    set huisseries(value: DictionaryItem[]) {
        this._huisseries = value;
    }

    get inlineAdresse(): string {
        return this._inlineAdresse;
    }

    get isolations(): DictionaryItem[] {
        return this._isolations;
    }

    set isolations(value: DictionaryItem[]) {
        this._isolations = value;
    }

    get linkNotaire(): string {
        return this._linkNotaire;
    }

    set linkNotaire(value: string) {
        this._linkNotaire = value;
    }

    get linkPhotos(): string {
        return this._linkPhotos;
    }

    set linkPhotos(value: string) {
        this._linkPhotos = value;
    }

    get linkPremierePhoto(): string {
        return this._linkPremierePhoto;
    }

    set linkPremierePhoto(value: string) {
        this._linkPremierePhoto = value;
        this._linkPremierePhoto16by9 = UrlService.addQueryparamToUrl(this.linkPremierePhoto, 'size', ImageSizeConst.D16_BY_9);
        this._linkPremierePhotoOptimized = UrlService.addQueryparamToUrl(this.linkPremierePhoto, 'size', ImageSizeConst.OPTIMIZED);
        this._linkPremierePhotoThumbnail = UrlService.addQueryparamToUrl(this.linkPremierePhoto, 'size', ImageSizeConst.THUMBNAIL);
    }

    get linkPremierePhoto16by9(): string {
        return this._linkPremierePhoto16by9;
    }

    get linkPremierePhotoOptimized(): string {
        return this._linkPremierePhotoOptimized;
    }

    get linkPremierePhotoThumbnail(): string {
        return this._linkPremierePhotoThumbnail;
    }

    get linkPrivatePhotos(): string {
        return this._linkPrivatePhotos;
    }

    set linkPrivatePhotos(value: string) {
        this._linkPrivatePhotos = value;
    }

    get linkPrivatePremierePhoto(): string {
        return this._linkPrivatePremierePhoto;
    }

    set linkPrivatePremierePhoto(value: string) {
        this._linkPrivatePremierePhoto = value;
    }

    get linkProtectedPhotos(): string {
        return this._linkProtectedPhotos;
    }

    set linkProtectedPhotos(value: string) {
        this._linkProtectedPhotos = value;
    }

    get linkProtectedPremierePhoto(): string {
        return this._linkProtectedPremierePhoto;
    }

    set linkProtectedPremierePhoto(value: string) {
        this._linkProtectedPremierePhoto = value;
    }

    get linkResponsableDossier(): string {
        return this._linkResponsableDossier;
    }

    set linkResponsableDossier(value: string) {
        this._linkResponsableDossier = value;
    }

    get logements(): BienLogement[] {
        return this._logements;
    }

    set logements(value: BienLogement[]) {
        this._logements = value;
    }

    get longueurFacade(): number {
        return this._longueurFacade;
    }

    set longueurFacade(value: number) {
        this._longueurFacade = value;
    }

    get loyerActuel(): number {
        return this._loyerActuel;
    }

    set loyerActuel(value: number) {
        this._loyerActuel = value;
    }

    get materiauxConstruction(): DictionaryItem[] {
        return this._materiauxConstruction;
    }

    set materiauxConstruction(value: DictionaryItem[]) {
        this._materiauxConstruction = value;
    }

    get materiauxCouverture(): DictionaryItem[] {
        return this._materiauxCouverture;
    }

    set materiauxCouverture(value: DictionaryItem[]) {
        this._materiauxCouverture = value;
    }

    get mitoyennete(): DictionaryItem {
        return this._mitoyennete;
    }

    set mitoyennete(value: DictionaryItem) {
        this._mitoyennete = value;
    }

    get montantQuotePartLoiAlur(): number {
        return this._montantQuotePartLoiAlur;
    }

    set montantQuotePartLoiAlur(value: number) {
        this._montantQuotePartLoiAlur = value;
    }

    get montantTravauxLoiAlur(): number {
        return this._montantTravauxLoiAlur;
    }

    set montantTravauxLoiAlur(value: number) {
        this._montantTravauxLoiAlur = value;
    }

    get nature(): DictionaryItem {
        return this._nature;
    }

    set nature(value: DictionaryItem) {
        this._nature = value;
    }

    get nombreBains(): number {
        return this._nombreBains;
    }

    set nombreBains(value: number) {
        this._nombreBains = value;
    }

    get nombreChambres(): number {
        return this._nombreChambres;
    }

    set nombreChambres(value: number) {
        this._nombreChambres = value;
    }

    get nombreCheminees(): number {
        return this._nombreCheminees;
    }

    set nombreCheminees(value: number) {
        this._nombreCheminees = value;
    }

    get nombreCoproprietairesLoiAlur(): number {
        return this._nombreCoproprietairesLoiAlur;
    }

    set nombreCoproprietairesLoiAlur(value: number) {
        this._nombreCoproprietairesLoiAlur = value;
    }

    get nombreDouches(): number {
        return this._nombreDouches;
    }

    set nombreDouches(value: number) {
        this._nombreDouches = value;
    }

    get nombreEtages(): number {
        return this._nombreEtages;
    }

    set nombreEtages(value: number) {
        this._nombreEtages = value;
    }

    get nombreEtagesBatiment(): number {
        return this._nombreEtagesBatiment;
    }

    set nombreEtagesBatiment(value: number) {
        this._nombreEtagesBatiment = value;
    }

    get nombreLogements(): number {
        return this._nombreLogements;
    }

    set nombreLogements(value: number) {
        this._nombreLogements = value;
    }

    get nombreLotsLoiAlur(): number {
        return this._nombreLotsLoiAlur;
    }

    set nombreLotsLoiAlur(value: number) {
        this._nombreLotsLoiAlur = value;
    }

    get nombrePieces(): number {
        return this._nombrePieces;
    }

    set nombrePieces(value: number) {
        this._nombrePieces = value;
    }

    get nombrePlacesParking(): number {
        return this._nombrePlacesParking;
    }

    set nombrePlacesParking(value: number) {
        this._nombrePlacesParking = value;
    }

    get nombreSallesBains(): number {
        return this._nombreSallesBains;
    }

    set nombreSallesBains(value: number) {
        this._nombreSallesBains = value;
    }

    get nombreSallesEau(): number {
        return this._nombreSallesEau;
    }

    set nombreSallesEau(value: number) {
        this._nombreSallesEau = value;
    }

    get nombreWc(): number {
        return this._nombreWc;
    }

    set nombreWc(value: number) {
        this._nombreWc = value;
    }

    get nomVoie(): string {
        return this._nomVoie;
    }

    set nomVoie(value: string) {
        this._nomVoie = value;
        this.setInlineAdresses();
    }

    get notaireId(): number {
        return this._notaireId;
    }

    set notaireId(value: number) {
        this._notaireId = value;
    }

    get numeroCle(): string {
        return this._numeroCle;
    }

    set numeroCle(value: string) {
        this._numeroCle = value;
    }

    get numeroEtage(): number {
        return this._numeroEtage;
    }

    set numeroEtage(value: number) {
        this._numeroEtage = value;
    }

    get numeroVoie(): number {
        return this._numeroVoie;
    }

    set numeroVoie(value: number) {
        this._numeroVoie = value;
        this.setInlineAdresses();
    }

    get occupation(): DictionaryItem {
        return this._occupation;
    }

    set occupation(value: DictionaryItem) {
        this._occupation = value;
    }

    get panneaux(): string {
        return this._panneaux;
    }

    set panneaux(value: string) {
        this._panneaux = value;
    }

    get panneauxPhotovoltaiques(): boolean {
        return this._panneauxPhotovoltaiques;
    }

    set panneauxPhotovoltaiques(value: boolean) {
        this._panneauxPhotovoltaiques = value;
    }

    get performanceEnergetique(): BienPerformanceEnergetique {
        return this._performanceEnergetique;
    }

    set performanceEnergetique(value: BienPerformanceEnergetique) {
        this._performanceEnergetique = value;
    }

    get pieceEauRdc(): boolean {
        return this._pieceEauRdc;
    }

    set pieceEauRdc(value: boolean) {
        this._pieceEauRdc = value;
    }

    get piscine(): boolean {
        return this._piscine;
    }

    set piscine(value: boolean) {
        this._piscine = value;
    }

    get plainPied(): DictionaryItem {
        return this._plainPied;
    }

    set plainPied(value: DictionaryItem) {
        this._plainPied = value;
    }

    get planSauvegardeLoiAlur(): boolean {
        return this._planSauvegardeLoiAlur;
    }

    set planSauvegardeLoiAlur(value: boolean) {
        this._planSauvegardeLoiAlur = value;
    }

    get pointEauCampagne(): boolean {
        return this._pointEauCampagne;
    }

    set pointEauCampagne(value: boolean) {
        this._pointEauCampagne = value;
    }

    get pointEauVille(): boolean {
        return this._pointEauVille;
    }

    set pointEauVille(value: boolean) {
        this._pointEauVille = value;
    }

    get quartier(): Quartier {
        return this._quartier;
    }

    set quartier(value: Quartier) {
        this._quartier = value;
    }

    get raccordements(): DictionaryItem[] {
        return this._raccordements;
    }

    get referenceInterne(): string {
        return this._referenceInterne;
    }

    set referenceInterne(value: string) {
        this._referenceInterne = value;
    }

    get residence(): DictionaryItem {
        return this._residence;
    }

    set residence(value: DictionaryItem) {
        this._residence = value;
    }

    get residenceService(): boolean {
        return this._residenceService;
    }

    set residenceService(value: boolean) {
        this._residenceService = value;
    }

    get responsableDossierId(): number {
        return this._responsableDossierId;
    }

    set responsableDossierId(value: number) {
        this._responsableDossierId = value;
    }

    get servitude(): string {
        return this._servitude;
    }

    set servitude(value: string) {
        this._servitude = value;
    }

    get sousSol(): boolean {
        return this._sousSol;
    }

    set sousSol(value: boolean) {
        this._sousSol = value;
    }

    get superficie(): number {
        return this._superficie;
    }

    set superficie(value: number) {
        this._superficie = value;
    }

    get surfaceAnnexe(): number {
        return this._surfaceAnnexe;
    }

    set surfaceAnnexe(value: number) {
        this._surfaceAnnexe = value;
    }

    get surfaceBalcon(): number {
        return this._surfaceBalcon;
    }

    set surfaceBalcon(value: number) {
        this._surfaceBalcon = value;
    }

    get surfaceBatie(): number {
        return this._surfaceBatie;
    }

    set surfaceBatie(value: number) {
        this._surfaceBatie = value;
    }

    get surfaceCour(): number {
        return this._surfaceCour;
    }

    set surfaceCour(value: number) {
        this._surfaceCour = value;
    }

    get surfaceCadastrale(): number {
        return this._surfaceCadastrale;
    }

    set surfaceCadastrale(value: number) {
        this._surfaceCadastrale = value;
    }

    get surfaceCarrez(): number {
        return this._surfaceCarrez;
    }

    set surfaceCarrez(value: number) {
        this._surfaceCarrez = value;
    }

    get surfaceCave(): number {
        return this._surfaceCave;
    }

    set surfaceCave(value: number) {
        this._surfaceCave = value;
    }

    get surfaceGarage(): number {
        return this._surfaceGarage;
    }

    set surfaceGarage(value: number) {
        this._surfaceGarage = value;
    }

    get surfaceNonBatie(): number {
        return this._surfaceNonBatie;
    }

    set surfaceNonBatie(value: number) {
        this._surfaceNonBatie = value;
    }

    get surfacePlancher(): number {
        return this._surfacePlancher;
    }

    set surfacePlancher(value: number) {
        this._surfacePlancher = value;
    }

    get surfaces(): BienSurface[] {
        return this._surfaces;
    }

    set surfaces(value: BienSurface[]) {
        this._surfaces = value;
    }

    get surfaceSejour(): number {
        return this._surfaceSejour;
    }

    set surfaceSejour(value: number) {
        this._surfaceSejour = value;
    }

    get surfaceTerrasse(): number {
        return this._surfaceTerrasse;
    }

    set surfaceTerrasse(value: number) {
        this._surfaceTerrasse = value;
    }

    get terrasse(): boolean {
        return this._terrasse;
    }

    set terrasse(value: boolean) {
        this._terrasse = value;
    }

    get titre(): string {
        return this._titre;
    }

    set titre(value: string) {
        this._titre = value;
    }

    get urlVisiteVirtuelle(): string {
        return this._urlVisiteVirtuelle;
    }

    set urlVisiteVirtuelle(value: string) {
        this._urlVisiteVirtuelle = value;
    }

    get usage(): DictionaryItem {
        return this._usage;
    }

    set usage(value: DictionaryItem) {
        this._usage = value;
    }

    get veranda(): boolean {
        return this._veranda;
    }

    set veranda(value: boolean) {
        this._veranda = value;
    }

    get viabilisable(): boolean {
        return this._viabilisable;
    }

    set viabilisable(value: boolean) {
        this._viabilisable = value;
    }

    get viabilisationAssainissement(): string {
        return this._viabilisationAssainissement;
    }

    set viabilisationAssainissement(value: string) {
        this._viabilisationAssainissement = value;
    }

    get viabilisationAssainissementCollectifRaccorde(): boolean {
        return this._viabilisationAssainissementCollectifRaccorde;
    }

    set viabilisationAssainissementCollectifRaccorde(value: boolean) {
        this._viabilisationAssainissementCollectifRaccorde = value;
    }

    get viabilisationComments(): string {
        return this._viabilisationComments;
    }

    set viabilisationComments(value: string) {
        this._viabilisationComments = value;
    }

    get viabilisationEau(): boolean {
        return this._viabilisationEau;
    }

    set viabilisationEau(value: boolean) {
        this._viabilisationEau = value;
        this.setRaccordements({code: DossierBienFieldsConst.viabilisationEau, label: 'Eau'} as DictionaryItem,
            this.viabilisationEau);
    }

    get viabilisationElectricite(): boolean {
        return this._viabilisationElectricite;
    }

    set viabilisationElectricite(value: boolean) {
        this._viabilisationElectricite = value;
        this.setRaccordements({
                code: DossierBienFieldsConst.viabilisationElectricite,
                label: 'Électricité'
            } as DictionaryItem,
            this.viabilisationElectricite);
    }

    get viabilisationFibre(): boolean {
        return this._viabilisationFibre;
    }

    set viabilisationFibre(value: boolean) {
        this._viabilisationFibre = value;
        this.setRaccordements({code: DossierBienFieldsConst.viabilisationFibre, label: 'Fibre'} as DictionaryItem,
            this.viabilisationFibre);
    }

    get viabilisationGaz(): boolean {
        return this._viabilisationGaz;
    }

    set viabilisationGaz(value: boolean) {
        this._viabilisationGaz = value;
        this.setRaccordements({code: DossierBienFieldsConst.viabilisationGaz, label: 'Gaz'} as DictionaryItem,
            this.viabilisationGaz);
    }

    get viabilisationTelecom(): boolean {
        return this._viabilisationTelecom;
    }

    set viabilisationTelecom(value: boolean) {
        this._viabilisationTelecom = value;
        this.setRaccordements({code: DossierBienFieldsConst.viabilisationTelecom, label: 'Télécom'} as DictionaryItem,
            this.viabilisationTelecom);
    }

    get viabilisationVoirie(): boolean {
        return this._viabilisationVoirie;
    }

    set viabilisationVoirie(value: boolean) {
        this._viabilisationVoirie = value;
        this.setRaccordements({code: DossierBienFieldsConst.viabilisationVoirie, label: 'Voirie'} as DictionaryItem,
            this.viabilisationVoirie);
    }

    get voie(): CommuneVoie {
        return this._voie;
    }

    set voie(value: CommuneVoie) {
        this._voie = value;
        this.setInlineAdresses();
    }

    get volets(): DictionaryItem[] {
        return this._volets;
    }

    set volets(value: DictionaryItem[]) {
        this._volets = value;
    }

    get vues(): DictionaryItem[] {
        return this._vues;
    }

    set vues(value: DictionaryItem[]) {
        this._vues = value;
    }

    get wcBroyeur(): boolean {
        return this._wcBroyeur;
    }

    set wcBroyeur(value: boolean) {
        this._wcBroyeur = value;
    }

    get zoneInondable(): boolean {
        return this._zoneInondable;
    }

    set zoneInondable(value: boolean) {
        this._zoneInondable = value;
    }

    get zonePLU(): string {
        return this._zonePLU;
    }

    set zonePLU(value: string) {
        this._zonePLU = value;
    }

    get zoneRisques(): boolean {
        return this._zoneRisques;
    }

    set zoneRisques(value: boolean) {
        this._zoneRisques = value;
    }

    couldUseNotestimV2(): boolean {
        return this.isAppartement() || this.isMaison();
    }

    getOneDescriptif(type: string): string {
        if (type === Bien.descriptifs.MANDAT && this.descriptifMandat) {
            return this.descriptifMandat;
        }

        return this.descriptif;
    }

    getSurfacePonderee(): number {
        return this.surfaces
            .map(surface => surface.surfacePonderee)
            .reduce((total, surfacePonderee) => total + surfacePonderee, 0);
    }

    hasAdresse(): boolean {
        if (this.commune.hasVoies) {
            return !!this.voie;
        }

        return !!this.nomVoie;
    }

    hasLocalisation(): boolean {
        return !!this.commune && this.hasAdresse();
    }

    hasSavedCadastres(): boolean {
        return this.cadastres.filter(cadastre => !!cadastre.id).length > 0;
    }

    isAppartement(): boolean {
        return this.nature.code === Bien.natures.APPARTEMENT;
    }

    isAutre(): boolean {
        return this.nature.code === Bien.natures.AUTRE;
    }

    isAutreTerrain(): boolean {
        return this.nature.code === Bien.natures.AUTRE_TERRAIN;
    }

    isBureaux(): boolean {
        return this.nature.code === Bien.natures.BUREAUX;
    }

    isGarage(): boolean {
        return this.nature.code === Bien.natures.GARAGE;
    }

    isHabitable(): boolean {
        return this.isAppartement() || this.isImmeuble() || this.isMaison();
    }

    isImmeuble(): boolean {
        return this.nature.code === Bien.natures.IMMEUBLE;
    }

    isInDOMTOM(): boolean {
        return !!this.commune?.isDOMTOM();
    }

    isLocauxCommerciaux(): boolean {
        return this.nature.code === Bien.natures.LOCAUX_COMMERCIAUX;
    }

    isMaison(): boolean {
        return this.nature.code === Bien.natures.MAISON;
    }

    isTerrainABatir(): boolean {
        return this.nature.code === Bien.natures.TERRAIN_A_BATIR;
    }

    isViabilisationAssainissementCollectifRaccorde(): boolean {
        return this.viabilisationAssainissement === Bien.viabilisationAssainissements.COLLECTIF && this.viabilisationAssainissementCollectifRaccorde;
    }

    setInlineAdresses(): void {
        const adresseChunks = [this.numeroVoie?.toString(), this.voie?.nom || this.nomVoie].filter(chunk => !!chunk);

        this._inlineAdresse = adresseChunks.join(' ')?.trim() || this.adresseReseau?.trim() || '';
        this._fullInlineAdresse = this._inlineAdresse;
        if (this.commune) {
            if (this._fullInlineAdresse) {
                this._fullInlineAdresse += ', ';
            }

            this._fullInlineAdresse += this.commune.fullname;
        }

        if (this.codePostal) {
            this._fullInlineAdresse += ' (' + this.codePostal + ')';
        }
    }

    setRaccordements(editedRaccordement: DictionaryItem, value: boolean): void {
        if (value) {
            this._raccordementsMap.set(editedRaccordement.code, editedRaccordement);
        } else {
            this._raccordementsMap.delete(editedRaccordement.code);
        }

        this._raccordements = Array.from(this._raccordementsMap, ([, raccordement]) => raccordement);
    }
}
