export default class EstimationNotestim {
    static readonly scanStatuts = {ERROR: 'error', IN_PROGRESS: 'in_progress', UPDATED: 'updated'};
    private _iframeUrl!: string;
    private _isInit = false;
    private _linkRapportMedia!: string;
    private _scanDataResalePrice!: number;
    private _scanGeneratedAt!: string;
    private _scanStatut!: string;

    get iframeUrl(): string {
        return this._iframeUrl;
    }

    set iframeUrl(value: string) {
        this._iframeUrl = value;
        this._isInit = !!this.iframeUrl;
    }

    get isInit(): boolean {
        return this._isInit;
    }

    get linkRapportMedia(): string {
        return this._linkRapportMedia;
    }

    set linkRapportMedia(value: string) {
        this._linkRapportMedia = value;
    }

    get scanDataResalePrice(): number {
        return this._scanDataResalePrice;
    }

    set scanDataResalePrice(value: number) {
        this._scanDataResalePrice = value;
    }

    get scanGeneratedAt(): string {
        return this._scanGeneratedAt;
    }

    set scanGeneratedAt(value: string) {
        this._scanGeneratedAt = value;
    }

    get scanStatut(): string {
        return this._scanStatut;
    }

    set scanStatut(value: string) {
        this._scanStatut = value;
    }

    isDifferent(estimationNotestim: EstimationNotestim): boolean {
        return this.linkRapportMedia !== estimationNotestim.linkRapportMedia ||
            this.scanDataResalePrice !== estimationNotestim.scanDataResalePrice ||
            this.scanGeneratedAt !== estimationNotestim.scanGeneratedAt ||
            this.scanStatut !== estimationNotestim.scanStatut;
    }

    isScanError(): boolean {
        return this.scanStatut === EstimationNotestim.scanStatuts.ERROR;
    }

    isScanInProgress(): boolean {
        return this.scanStatut === EstimationNotestim.scanStatuts.IN_PROGRESS;
    }
}
