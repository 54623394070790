import angularJS from '@shared/angularJS/global.ng';
import {IHttpRequestConfigHeaders, IModule, IPromise, IQService} from 'angular';
import {NgLocalStorageServiceClient} from '@legacy/app/soqrate/soqrate';
import {NgClientConfig, NgClientHttp} from '@legacy/app/client/client';
import {NgEsk} from '@legacy/app/managers/ressources';
import {LocalStorageService} from '@core/storage/local-storage.service';
import {MediaFactory} from '@models/medias/media/media.factory';
import IInjectorService = angular.auto.IInjectorService;
import {NgMediaManager} from '@legacy/app/managers/managers';
import {AuthInterceptor} from '@core/auth/core/auth-interceptor';

export default function getClientService(module: IModule): void {
    (function (angular) {
        'use strict';

        module.service('ClientService', Service);

        /**
         * Communication with API server
         *
         * @param ClientHttp
         * @param ClientConfig
         * @param $q
         * @param Ng2LocalStorageService
         * @param Ng2MediaFactory
         * @param $injector
         * @param Ng2AuthInterceptor
         */
        Service.$inject = ['ClientHttp', 'ClientConfig', '$q', 'Ng2LocalStorageService', 'Ng2MediaFactory', '$injector', 'Ng2AuthInterceptor'];
        function Service(this: any,
                         clientHttp: NgClientHttp,
                         clientConfig: NgClientConfig,
                         $q: IQService,
                         ng2LocalStorageService: LocalStorageService,
                         ng2MediaFactory: MediaFactory,
                         $injector: IInjectorService,
                         ng2AuthInterceptor: AuthInterceptor) {
            const self = this;
            const routes = {} as Record<string, { method: string, path: string, useCache: boolean }>;
            const clientStorage = ng2LocalStorageService.get<NgLocalStorageServiceClient>('client') || {};

            if (!angular.isObject(clientStorage.cache)) clientStorage.cache = {};

            self.razCache = razCache;
            self.addRoute = addRoute;
            self.getPathCleanQueryParams = getPathCleanQueryParams;
            self.execRoute = execRoute;
            self.execLink = execLink;
            self.uploadFile = uploadFile;

            /**
             * RAZ cache
             */
            function razCache() {
                ng2LocalStorageService.remove('client');
            }

            /**
             * Add route to be executed
             *
             * @param name
             * @param options
             * @param useCache
             */
            function addRoute(name: string, options: {
                method: string,
                path: string,
                useCache: boolean
            }, useCache?: boolean): void {
                routes[name] = options;
                routes[name].useCache = useCache!;
            }

            /**
             * Get path and clean queryParams
             *
             * @param name
             * @param queryParams
             * @returns {String}
             */
            function getPathCleanQueryParams(name: string, queryParams: Record<string, unknown>) {
                const route = routes[name];
                const regexRouteParam = /{([a-zA-Z_]+)}/g;
                const slugs = {} as Record<string, unknown>;
                let slug: string[];

                if (!angular.isObject(route)) {
                    throw new Error("La route \"" + name + "\" n'existe pas.");
                }

                while ((slug = regexRouteParam.exec(route.path)!) !== null) {
                    if (!angular.isDefined(queryParams[slug[1]])) {
                        throw new Error("Le slug \"" + slug[1] + "\" n'existe pas.");
                    }

                    slugs[slug[1]] = queryParams[slug[1]];
                    delete queryParams[slug[1]];
                }

                let path = route.path;
                for (const [key, value] of Object.entries(slugs as Record<string, unknown>)) {
                    if (typeof value === 'number' || typeof value === 'string') {
                        path = path.replace('{' + key + '}', value.toString());
                    }
                }

                return path;
            }

            /**
             * Make request HTTP
             *
             * @param name
             * @param params
             * @param data
             * @param headers
             * @returns {promise}
             */
            function execRoute<T>(name: string, params?: unknown, data?: unknown, headers?: IHttpRequestConfigHeaders): IPromise<T> | undefined {
                const queryParams = (angular.copy(params) ?? {}) as Record<string, unknown>;
                const xdebug = ng2AuthInterceptor.getXDebugSession();

                if (xdebug) {
                    queryParams.XDEBUG_SESSION = xdebug;
                }

                const path = self.getPathCleanQueryParams(name, queryParams);
                let fullUrl: string;

                switch (routes[name].method) {
                    case 'GET':
                        fullUrl = clientConfig.getFullUrl(path, queryParams);
                        if (routes[name].useCache && angular.isDefined(clientStorage.cache![fullUrl])) {
                            return $q.resolve(clientStorage.cache![fullUrl] as T);
                        }

                        return clientHttp.get(path, queryParams, headers).then(data => {
                            if (routes[name].useCache && (data as { pages: number }).pages === 1) {
                                clientStorage.cache![fullUrl] = data;
                                ng2LocalStorageService.set('client', clientStorage);
                            }

                            return data as T;
                        });
                    case 'POST':
                        return clientHttp.post(path, data, headers) as IPromise<T>;
                    case 'PUT':
                        return clientHttp.put(path, data, headers) as IPromise<T>;
                    case 'PATCH':
                        return clientHttp.patch(path, queryParams, data, headers) as IPromise<T>;
                }

                return $q.reject('UNKNOWN METHOD : ' + routes[name].method);
            }

            /**
             * Make request GET on defined route
             *
             * @param route
             * @returns {promise}
             */
            function execLink(route: string) {
                return clientHttp.get(route);
            }

            /**
             * Upload file
             *
             * @param routeName
             * @param file
             * @returns {Promise}
             */
            function uploadFile(routeName: string, file: File & { _esk: NgEsk }) {
                let promise: IPromise<unknown>;

                file._esk = {status: 'WAITING'};
                if (routeName === 'medias.upload') {
                    const deferred = $q.defer();
                    const transfer = ng2MediaFactory.upload(file);

                    transfer.progress$.subscribe(progress => deferred.notify({
                        xhr: {abort: () => transfer.abort()},
                        progress,
                    }));
                    transfer.race$.subscribe({
                        complete: () => deferred.resolve($injector.get<NgMediaManager>('MediaManager').createFromNg2(transfer.response)),
                        error: error => deferred.reject(error),
                    });

                    promise = deferred.promise;
                } else {
                    throw new Error('ClientService.uploadFile est déprécié pour tout sauf pour les medias.');
                }

                return promise.then(data => {
                    file._esk.status = 'UPLOADED';

                    return data;
                }, error => {
                    if (error === 'abort') {
                        error = "Annulation manuelle du téléchargement.";
                    }

                    file._esk.status = 'ERROR';
                    file._esk.error = error;

                    return $q.reject(error);
                }, evt => {
                    file._esk.status = 'IN_PROGRESS';
                    file._esk.uploadXhr = evt.xhr;
                    file._esk.progress = evt.progress ? evt.progress : Math.round(evt.loaded / evt.total * 100);

                    return evt;
                }).finally(() => file._esk.uploadXhr = undefined!);
            }
        }
    })(angularJS);
}
